import React from 'react'
import TextSplitter from '../../components/TextSplitter'

export default function SpaceUseScreen() {

    const legalText = `This Space Use License (this “Agreement”) is made as of the Agreement Time between the Host and the Guest for use of the Space located in the Building.
    The Host and Guest agree as follows:
    License. Subject to the terms and conditions of this Agreement, the Host grants Guest an exclusive, non-transferable license to occupy and use the Space in the Building (the “License”) during the Term for the agreed fees, deposits and Taxes, and subject to agreed usage restrictions and limitations in the House Rules, and the redezio Rules and Guidelines (sacha.app/terms-of-use), and the Guest accepts such License.
    Host House Rules and redezio Rules and Guidelines. During the Term, the Guest agrees to accept and abide by the House Rules, which may be revised and amended by the Host and redezio without the prior consent of, or notice to, the Guest. House Rules amended in a Space during the Guest’s stay in respective Space will apply after the reservation in respective Space is completed.
    Payment. The Guest agrees to pay the Fees, deposits, and Taxes for use of the Space to the Service.
    Definitions. Terms used and not otherwise defined in this Agreement or the Exhibit and Schedules shall have the meanings set forth in the Section entitled 
    "Definitions" below.
    Entire Agreement. This Agreement (including the Exhibits) constitutes the entire agreement between the Host and the Guest regarding the use of the Space and supersedes any prior agreements between the Host and Guest relating to Guest's use of the Space.
    
    DEFINITIONS
    “Agreement Time” shall mean the date and time the reservation for a particular use and Term is booked through the Service;
    “Building” shall be the Building in which the Space is located as set by the Host in the Profile for the Space as of the Agreement Time;
    “redezio Rules and Guidelines” shall mean those rules and guidelines published from time to time by redezio with respect to the permitted usage of a Space.
    “Fees” shall be the Fees set by the Host for use of the Space during the Term, including the “Space Use Charge” as set by the Host in the Profile for the Space as of the Agreement Time;
    “Guest” shall mean the Guest and the Guest’s email and address shall have the values set by the Guest in the Guest’s Profile as of the Agreement Time. “Guest” may sometimes be referred to as “You” or derivatives thereof in this Agreement. Guest is not a tenant and maintains no leasehold, tenancy or other real property rights with respect to the Space;
    “Host” shall mean the Host and the Host’s email and address shall have the values set by the Host in the Host’s Profile as of the Agreement Time;
    “Host Area” shall mean the Space or any space leased or owned by the Host of which the Space is a part;
    “Host Personal Property” shall mean all furnishings, equipment, fixtures or other personal property located in, on or around the Space, the Shared Facilities, the Host Area or the Common Areas which is not owned by You or Your Invitees;
    “House Rules” shall mean the terms, conditions, rules, regulations, policies and procedures provided by the Host relating to the occupancy and use of the Space, including Building security procedures, IT access and use procedures, maximum occupancy limitations, specific state law requirements and other terms or procedures provided by the Host;
    “Invitees” shall mean Guest’s employees, agents, guests, invitees, contractors and subcontractors;
    “Service” shall mean the redezio service;
    “Maximum Occupancy” shall be the value set by the Host in the Profile for the Space as of the Agreement Time;
    “Space” shall be the Space the Guest reserves by booking a reservation through the Service;
    “Taxes” shall be the paid by the Host, and Host is responsible for paying all applicable Taxes associated with Your use of the Service;
    “Term” shall be the term of the reservation for the Space specified by the Guest and approved by the Host, for which Fees are charged and paid.
    
    LICENSE TERMS
    1. Compliance
    Guest agrees to abide by and cause its Invitees to abide by this Agreement and any applicable House Rules provided by the Host.
    2. Possession and Delivery
    The Space is accepted by the Guest in its “as-is,” “where-is,” and “as available” condition and configuration. If, for any reason, the Host is unable to provide access to, or use of, the Space at the beginning of the Term, Guest shall have the right to cancel the License with no penalty and be entitled to a full refund of Fees, deposits and any other amounts paid.
    3. Check In/Out
    The Guest shall check in and out of Host Space using the Service as applicable during the Term You are reserving the Space, and shall follow the redezio Rules and Guidelines, including:
    Make Your reservation in advance to secure Your Day or Hourly Reservation.
    Check in using your Arrival Instructions as provided by the Host when You arrive for Your Day Reservation, before You get seated, or if You are arriving for an Hourly Reservation, announce Yourself upon entry to the Host’s Community Manager, if available.
    Your redezio Pass is just for You, not Your whole team.
    To bring a guest, please rent a private meeting room or have Your guest rent a space through redezio.
    Mind the closing time, and prepare to leave 5 minutes before close.
    Space Use and Access
    You agree to use the Space provided to You for general office purposes only and You may not use the Space to carry out any illegal activities or use the Space (a) in violation of law, local regulations (including zoning laws) governing the Space, the House Rules, the redezio Rules and Guidelines, or any other reasonable regulations or rules applied by Host for the duration of the Term, or (b) for any immoral, unlawful, or objectionable purposes. Further, You shall not use or permit the usage of any illegal drug or substance and shall not make or permit any unreasonable or unnecessary noises or odors in or upon the Space or the Building. You shall not commit, or suffer to be committed, any waste upon the Space or any nuisance (public or private) or other act or thing of any kind or nature whatsoever that may disturb the quiet enjoyment or cause unreasonable annoyance of any other occupants in the Building.
    You agree not to exceed the Maximum Occupancy of the Space.
    You shall not make alterations, additions or improvements to the Space, including the installation of lighting or any phone or data lines, or the affixation of any notices, names, directions, documents, posters, paintings or the like on the walls of the Space or the Building.
    You shall not generate, store, install, dispose of or otherwise handle any hazardous materials in the Space, or in or around the Building, in any manner contrary to any applicable law. You shall be liable for the costs of any removal, clean- up and/or remediation of any hazardous materials released by You or Your Invitees.
    You agree that the Host has the right to require that You relocate to another Space in the same Building of equal or larger size and similar configuration for the remainder of the Term, provided that the Fees for such new Space are no greater than the Fees for Your current Space.
    The Host or its authorized representatives may enter the Space at any reasonable time for any lawful reason, upon reasonable notice. Unless there is an emergency, the Host will, as a matter of courtesy, try to inform You in advance when the Host needs access to the Space to carry out testing (including security testing), repair or work other than routine inspection, cleaning and maintenance.
    5. Good Care
    You must take good care of and not damage, waste or make any changes to the Space or Host Area or the Building (including Common Areas and Shared Facilities). You shall not alter, add, replace, remove or damage any Host Personal Property. At the end of the Term, You must deliver the Space and all Host Personal Property to the Host in good condition, normal wear and tear excepted. If any damage (beyond normal wear and tear) to the Space or the Host Personal property should occur during the Term while in Your care, custody or control, You agree to pay reasonable repair/replacement costs and to notify the Host immediately upon discovery of such damage occurring. You are liable for any damage caused by You or Your Invitees to the Space, the Shared Facilities, the Common Areas, the Host Personal Property or the Building.
    6. Common Areas
    If available at the Building, You may also have access to and non-exclusive shared use of any portions of the Building designated for common use of tenants and others ("Common Areas"), as, and to the extent, described in the House Rules. The Common Areas may be changed, relocated, altered, eliminated or otherwise modified at any time during the Term without the consent of, or notice to, Guest. Unless otherwise set forth in the House Rules, the right to parking is not provided under this Agreement.
    7. Shared Facilities
    If available at the Building, You may also have access to and non-exclusive use of any shared office equipment and kitchenettes (the “Shared Facilities”) located near the Space on a first-come, first-served basis as, and to the extent, described in the House Rules. The Host may make changes to the Shared Facilities from time to time during the Term including, without limitation, removal of all or portions of the Shared Facilities without Your consent or notice to You.
    8. Keys and Security
    Any keys, codes or entry cards for the Space or the Building, which the Host lets You use, remain the Host’s property at all times. You shall not make any copies of them or allow anyone else to use them without the Host’s consent. Any loss of keys or entry cards must be reported to the Host immediately, and You must pay the cost of replacement keys or cards and or changing locks, if reasonably required by the Host.
    You shall not place any additional locks or bolts of any kind upon any of the doors or windows of the Space or Building, nor make any changes to existing locks or the mechanisms thereof.
    You accept that the Host may offer an option for You to unlock designated doors with the Mobile Software, limited to the duration of the Term.
    9. Name and Address
    You may only conduct business in Your name. You shall not put up any signs on the doors to the Space or Building or anywhere else, which are visible from outside the Space You are using, or the Building. If permitted by Host, You may advise Building security or the Space’s front desk team that you are receiving an Invitee(s).
    10. Mail and Packages
    Mail and packages may not be delivered to You at the Space or Building unless permitted by the House Rules. The postal services may be subject to additional federal, state, and local requirements.
    11. Conduct
    You acknowledge that the Host is and will continue to be an equal opportunity employer and that the Host prohibits any form of discrimination in employment, against any of its employees (whether by its employees, its clients, including You, or others), including, on the basis of race, color, creed, religion, age, gender, marital status, sexual orientation, national origin, or disability, or other characteristics protected by law. In recognition of this policy, You and Your officers, directors, employees, shareholders, partners, agents, representatives, contractors, customers, or Invitees shall be prohibited from participating in any type of harassing or abusive behavior to employees of the Host or its affiliates, other clients or invitees, verbal or physical, in the Building for any reason.
    You further agree, upon the request of the Host, to cooperate with the Host in its efforts to enforce and maintain its equal employment opportunity, non-discrimination and anti-harassment policies.
    The Host may immediately terminate this Agreement without cost or penalty if Guest, any of Guest's staff or Invitees, engage in any behavior that the Host reasonably deems is contrary to such policies. In any such event, all Fees and deposits previously paid shall be forfeited.
    12. Damages and Insurance
    You are responsible for any damage You cause to the Space, any Host Personal Property or the Building beyond normal wear and tear. You are responsible for, and acknowledge having been advised to, arrange insurance for Your personal property against all risks and for Your liability to and for Your employees and third parties and for maintaining any specific insurance set forth in the House Rules. You expressly assume and bear the risk of damage, loss, theft or misappropriation with respect to any of Your personal property and liability to and for Your employees and third parties. You agree, as a material part of the consideration to be rendered by the Host under this Agreement, to waive any right of recovery against the Host, redezio, their directors, officers and employees, for any claim, damage, loss, theft or misappropriation of Your property under Your care, custody or control and any liability to and for You, Your employees and third parties, including for personal injuries or death to You or Your Invitees in or about the Space and the Building, and You agree to hold the Host and redezio exempt and harmless, and to defend the Host from and against any claim, damage and injury to any such person or to such property, arising from, related to or in connection with, Your use of the Space or from Your failure to keep the Space premises in good condition and repair as provided in this Agreement. All property and fixtures in the Space is understood to be under Your control during the Term.
    13. Payment
    If there are Fees for the use of the Space during the Term, You shall pay the Fees and Taxes to the Service. The Fees and Taxes are charged within 24 hours after the end of the Term, and You shall maintain a minimum of one valid credit card in Your Service user profile until You receive receipt that full payment of Fees and Taxes is completed.
    14. Cancellation
    All Fees and Taxes paid by Guests are non-refundable, except as expressly stated in this Agreement.
    Unless the Service specifies otherwise, You may cancel a redezio Day Pass up to 24 hours before your morning of check-in. Within 24 hours of the reservation start time, it is no longer possible to cancel and You will be billed for the complete reservation including reservation Fees and Taxes. You have 15 minutes after making a reservation to cancel without penalty, no matter when You book.
    
    Unless the Service specifies otherwise, with respect to Hourly Reservation only, You may cancel up until two 24 hours before Your reservation begins (the start of the Term) free of charge. Within 24 hours of the reservation start time, it is no longer possible to cancel and You will be billed for the complete reservation including reservation Fees and Taxes. You have 15 minutes after making a reservation to cancel without penalty, no matter when You book.
    The Host may cancel this Agreement if a contract has been entered into for the sale or lease of the Building.
    The Host shall not cancel this Agreement except as provided in this Agreement.
    15. Default
    You shall be considered in default of this Agreement if You fail to comply with any term of this Agreement. Upon any default, the Host shall have the right without notice to terminate this Agreement, in which case You shall immediately surrender the Space and the Host Personal Property to the Host. If You fail to surrender the Space and/or the Host Personal Property, the Host may, in compliance with applicable law and without prejudice to any other right or remedy, enter upon and take possession of the Space and the Host Personal Property and You shall be liable for all past due Fees and Taxes, all Fees and Taxes due for the remainder of the Term, all costs incurred by Host to retake possession of the Space and Host's Personal Property, and other losses and damages which Host may suffer, directly or indirectly, as a result of Guest's default.
    16. Indemnity
    You agree to fully indemnify, defend and hold the Host, its landlord, if applicable, any mortgagee and redezio, its employees, officers, directors, affiliates and agents, and its Service harmless from and against any loss, liability, claim, demand, damages, costs and expenses, including reasonable attorneys' fees, arising out of, relating to, or in connection with Your and Your Invitees' use of a Space, Common Areas or Shared Facilities, the Building or any violation of applicable law, this Agreement or House Rules. Nothing contained in this Agreement shall be construed to create privity of estate or of contract between You and Host's landlord, if applicable.
    17. Disclaimer
    EXCEPT AS PROVIDED HEREIN AND IN ANY HOUSE RULES, THE HOST IS PROVIDING ITS SPACE TO THE GUEST "AS IS," “WHERE IS” AND “AS AVAILABLE,” AND HOST DISCLAIMS ANY AND ALL OTHER REPRESENTATIONS AND WARRANTIES WITH RESPECT TO THE SPACE, WHETHER EXPRESS OR IMPLIED, INCLUDING IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. HOST CANNOT AND DOES NOT GUARANTEE AND DOES NOT PROMISE TO GUEST, ANY SPECIFIC RESULTS FROM USE OF THE SPACE. HOST DOES NOT REPRESENT OR WARRANT THAT THE SPACE WILL MEET YOUR REQUIREMENTS; THAT THE SPACE MEETS APPLICABLE LEGAL STANDARDS OR IS SAFE AND SUITABLE FOR YOUR INTENDED USE.
    18. Liability
    IN NO EVENT WILL HOST, redezio OR ITS RESPECTIVE DIRECTORS, EMPLOYEES, AGENTS, AFFILIATES OR SUPPLIERS BE LIABLE TO GUEST OR ANY PARTY CLAIMING THROUGH GUEST, FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING FOR ANY LOST PROFITS, LOST DATA, VIRUSES, CYBER-SECURITY ATTACKS, PERSONAL INJURY, DEATH OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, ARISING FROM, RELATED TO OR IN CONNECTION WITH, THE GUEST’S USE OF THE SPACE, ANY CONTENT OR OTHER MATERIALS ON, ACCESSED THROUGH OR DOWNLOADED FROM THE SERVICE, OR GUEST’S USE OF THE SPACE, EVEN IF THE HOST IS AWARE OR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, AND TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, HOST'S LIABILITY TO GUEST OR ANY PARTY CLAIMING THROUGH GUEST, FOR ANY CAUSE, CLAIM OR LOSS WHATSOEVER, AND REGARDLESS OF THE FORM OF THE ACTION, IS LIMITED TO THE AMOUNT OF FEES PAID FOR THE SPACE, IN THE 12 MONTHS PRIOR TO THE INITIAL ACTION GIVING RISE TO LIABILITY. THIS IS AN AGGREGATE LIMIT. THE EXISTENCE OF MORE THAN ONE CLAIM HEREUNDER WILL NOT INCREASE THIS LIMIT.
    YOU UNDERSTAND AND AGREE THAT NEITHER redezio NOR ITS SERVICE IS A PARTY TO ANY AGREEMENTS ENTERED INTO BETWEEN GUEST AND HOST, NOR IS redezio OR ITS SERVICE A REAL ESTATE BROKER, AGENT OR INSURER IN CONNECTION WITH THIS AGREEMENT. THE PARTIES SHALL INDEMNIFY, DEFEND AND HOLD HARMLESS redezio AND ITS SERVICE FROM AND AGAINST ANY LOSS, LIABILITY, CLAIM, DEMAND, DAMAGES, COSTS AND EXPENSES, INCLUDING REASONABLE ATTORNEYS' FEES, ARISING OUT OF, RELATED TO, OR IN CONNECTION WITH ANY USE OF THE SPACE, OR ANY VIOLATION OF APPLICABLE LAW, THIS AGREEMENT OR HOUSE RULES.
    19. Suspension of Services
    The Host may by notice suspend of the provision of services (including access to the Space) for reasons of political unrest, strikes, terrorism, Acts of God or other events beyond the Host’s or the Host’s landlord’s reasonable control. This Agreement shall automatically terminate if the Space is rendered unusable as a result of a fire, earthquake, other casualty or a condemnation, natural or man-made. As between Host and Guest, all proceeds of any condemnation award shall belong to Host and all insurance proceeds of Host shall be retained by and belong to Host. The Host may also suspend the provision of services (including access to the Space) in the event the Space or the Building is being renovated or repaired, in which event You will be relocated to another space within the Building, or if necessary, to another Building, all at the Host’s reasonable cost.
    20. Sublicensing
    You may not sublicense, assign, transfer any interest in this Agreement or allow any third party that is not an Invitee to use any portion of the Space, the Shared Facilities or the Common Areas.
    21. No Lease
    YOU ACKNOWLEDGE THAT THIS AGREEMENT IS NOT A LEASE OR ANY OTHER INTEREST IN REAL PROPERTY. IT IS A CONTRACTUAL ARRANGEMENT THAT CREATES A REVOCABLE LICENSE. The parties do not intend to create a lease or any other interest in real property for the benefit of Guest through this Agreement. The Host retains legal possession and control of the Space assigned to Guest. The Host’s obligation to provide space and services to Guest is subject, in all respects, to the terms of the Host’s lease with the Host’s landlord, if applicable. This Agreement and the License granted hereunder shall terminate simultaneously with the termination of the Host’s master lease or the termination of the operation of the Host Area for any reason at no cost or penalty to Host. You acknowledge and agree that You do not have any rights under the Host’s lease or periodic tenancy agreement (by contract or by law) with its landlord, if applicable. When this Agreement expires or is earlier terminated, Your license to occupy the Space shall automatically be revoked, and You shall have no continuing right of possession whatsoever. You agree to remove Your personal property and leave the Space as of the date of such expiration or termination. The Host is not responsible for Your personal property left in the Space after expiration or termination. If You fail to remove Your personal property, at the Host’s option, such personal property shall (a) be deemed conveyed to the Host and shall become the property of the Host, or (b) be removed from the Space by the Host at the Guest’s expense.
    22. No Broker
    You represent that You have dealt with no broker in connection with this Agreement. You agree to indemnify, defend and hold the Host harmless from any claims of any brokers claiming to have represented You in connection with this Agreement.
    23. Subordination
    This Agreement and any applicable House Rules are subordinate to any underlying lease, mortgage, deed of trust, ground lease or other lien now or subsequently arising upon the Space or the Building and to renewals, modifications, refinancings and extensions thereof including the Host’s lease with the Host’s landlord and to any other agreements to which the Host’s lease is subordinate. If and to the extent such subordination infers, or would create, a leasehold or tenancy interest for Guest, such subordination provision shall be deemed inapplicable.
    24. Jurisdiction
    This Agreement shall be exclusively and irrevocably interpreted and enforced in accordance with the laws of the state or commonwealth in which the Building is located, without regard to its conflict of laws provisions.
    25. Space TYPES
    Space Types are described on the redezio Site.
    26. Terms & Policies Summary
    A summary of redezio legal docs is available at www.sacha.app/legal/terms-of-use.
    27. Last Revised
    We published this Space Use License as of Feb 23rd, 2021.`


    return (
        <div className='overflow-y-auto mx-auto'>
            <div className='bg-white max-w-3xl m-10 p-3 rounded shadow'>
                <h4 className='text-2xl mb-5'>
                    Space Use Agreement
    </h4>
                <TextSplitter string={legalText} />
            </div>
        </div>
    )
}