import { 
    createSlice,
    createSelector
 } from '@reduxjs/toolkit'

const spaceSlice = createSlice({
    name: 'spaces',
    initialState: [],
    reducers: {
        addSpaces: (state, action) => state = action.payload,
        editSpace: (state, action) =>{
            state = [...state, action.payload]
        },
        removeSpace: (state, action) => {
            const index = state.indexOf(action.payload)
            const tempState = state.slice(index)
            return  state = [...tempState]
        }
    }
})

export const { addSpaces, editSpace, removeSpace } = spaceSlice.actions

export const getSpaces = createSelector(state => state)
export const getSpaceById = createSelector(state => spaceId => state.filter(s => s.id === spaceId));

export default spaceSlice.reducer