import {
    createSlice,
    createSelector,
} from '@reduxjs/toolkit'

const reservationSlice = createSlice({
    name: 'reservations',
    initialState: [],
    reducers: {
        addReservations: (state, action) => state = action.payload,
        updateReservationStatusLocally: (state, action) => {
            const index = state.findIndex(i => i.id === action.payload.id)
            const temp = state
            temp[index].status = action.payload.status
            return temp
        }
    }
})

export const { addReservations, updateReservationStatusLocally } = reservationSlice.actions

export const getReservations = createSelector(state => state)

export default reservationSlice.reducer