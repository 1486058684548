import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Fragment, useEffect, useState } from "react"
import { airbnb, analytics, atlassian, coworkers, coworking, coworkingAnimation, dropbox, freelancingAnimation, handshake, map, netflix, office, personpicture, productivity, slack, slackLogo, teamwork, trends, uber, wework } from '../../assets'
import { signInAnonymously } from "../../utils/api"
import { Dialog, Transition } from '@headlessui/react'
import { submitEmailToWaitlist } from '../../utils/api'
import { Link } from 'react-router-dom'
import Lottie from 'react-lottie'
import { LinkedinIcon, LinkedinShareButton } from 'react-share'

import MessengerCustomerChat from 'react-messenger-customer-chat';
import Footer from "../../components/splash/Footer"

const stats = [
    { label: 'Employees would quit if not allowed to be flexible', value: '39%' },
    { label: 'Employees want control of when, where and how they work', value: '95%' },
    { label: 'Employees rank work-life balance as #1 priority', value: '62%' },
    { label: 'Employees rate focus on well-being is critical', value: '80%' },
]

const includedFeatures = [
    'Advisory Team',
    'Coworking Membership',
    'Personal Onboarding',
    'Discounts on future expansions',
]


const logos = [
    { name: 'Transistor', url: 'https://tailwindui.com/img/logos/transistor-logo-gray-400.svg' },
    { name: 'Mirage', url: 'https://tailwindui.com/img/logos/mirage-logo-gray-400.svg' },
    { name: 'Tuple', url: 'https://tailwindui.com/img/logos/tuple-logo-gray-400.svg' },
    { name: 'Laravel', url: 'https://tailwindui.com/img/logos/laravel-logo-gray-400.svg' },
    { name: 'StaticKit', url: 'https://tailwindui.com/img/logos/statickit-logo-gray-400.svg' },
    { name: 'Workcation', url: 'https://tailwindui.com/img/logos/workcation-logo-gray-400.svg' },
]

const features = [
    {
        name: 'Access to dedicated workspace worldwide',
        description: 'With over 600 locations there is always a seat for your team',
    },
    {
        name: 'Bookable conference rooms and private offices',
        description: 'Need more than a phone booth, book a room or office with your included credits',
    },
    {
        name: 'Phone booths',
        description: 'Have that call, take it in a quiet place',
    },
    {
        name: 'Conferencing A/V',
        description: 'Have a board meeting, be fully prepared for anything you need',
    },
    {
        name: 'High speed Wi-Fi',
        description: 'Never worry about the internet speed of your employees',
    },
    {
        name: 'Unlimited coffee and tea',
        description: 'Get all the fuel you need to keep you focused and engaged',
    },
]

function SubmittedModal({ open, setOpen }) {
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={setOpen}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                            <div>
                                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                    <FontAwesomeIcon icon='check' className="text-blue-600" size='2x' />
                                </div>
                                <div className="mt-3 text-center sm:mt-5">
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                        Want to save even more?
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Help us grow our community so we can offer even better deals and perks. This is just the start.
                                        </p>
                                    </div>

                                    <div className='mt-5'>
                                        <LinkedinShareButton
                                            title='Save at least $700 per employee'
                                            summary='We give small businesses deals on office.'
                                            source='https://sacha.app'
                                            url='https://sacha.app'
                                        >
                                            <LinkedinIcon round={true} size={50} />
                                        </LinkedinShareButton>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-6">
                                <button
                                    type="button"
                                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
                                    onClick={() => setOpen(false)}
                                >
                                    Thank You
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}




export default function MembershipScreen() {

    const [email, setEmail] = useState()
    const [openModal, setOpenModal] = useState(false)

    function addEmailToWaitlist(e) {
        e.preventDefault()
        return submitEmailToWaitlist(email, 'membership').then(() => setOpenModal(true))
    }

    return (
        <div className="bg-white">
            <MessengerCustomerChat
                pageId="108612044771543"
                appId="955024938397778"
            />
            <main>
                <SubmittedModal open={openModal} setOpen={setOpenModal} />
                {/* Hero section */}
                <div className='flex items-center pt-8 pl-10 max-w-6xl mx-auto row justify-between'>
                    <Link to='/' className='flex row items-center justify-center'>
                        <FontAwesomeIcon icon='building' size='2x' className='text-blue-500 mr-3' />
                        <h1 className='text-blue-500'>Sacha</h1>
                    </Link>

                </div>
                <div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-48 grid grid-cols-1 row-end-2">
                    <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24 cols-span-1">
                        <div>
                            <div className="mt-20">
                                <div className="mt-6 sm:max-w-xl">
                                    <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                                        {/* Enterprise discount without the enterprise */}
                                        Save at least $700 per employee
                                    </h1>
                                    <p className="mt-6 text-xl text-gray-500">
                                        {/* Get enterprise discounts on coworking as a small business or startup with Sacha */}
                                        We take care of the commercial real estate negotiations so you don't have to
                                    </p>
                                </div>
                                <form onSubmit={e => addEmailToWaitlist(e)} className="mt-12 sm:max-w-lg sm:w-full sm:flex" id='first'>
                                    <div className="min-w-0 flex-1">
                                        <label htmlFor="hero_email" className="sr-only">
                                            Email address
                                        </label>
                                        <input
                                            id="hero_email"
                                            type="email"
                                            className="block w-full border border-gray-300 rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                            placeholder="Enter your email"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="mt-4 sm:mt-0 sm:ml-3">
                                        <button
                                            type="submit"
                                            className="block w-full rounded-md border border-transparent px-5 py-3 bg-blue-500 text-base font-medium text-white shadow hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:px-10"
                                        >
                                            Sign up
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="sm:mx-auto sm:max-w-3xl sm:px-6 row-start-1">
                        <div className="sm:py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                            <div className="hidden sm:block">
                                <div className="absolute inset-y-0 left-1/2 w-screen bg-gray-50 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full" />
                                <svg
                                    className="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0"
                                    width={404}
                                    height={392}
                                    fill="none"
                                    viewBox="0 0 404 392"
                                >
                                    <defs>
                                        <pattern
                                            id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                                            x={0}
                                            y={0}
                                            width={20}
                                            height={20}
                                            patternUnits="userSpaceOnUse"
                                        >
                                            <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                        </pattern>
                                    </defs>
                                    <rect width={404} height={392} fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
                                </svg>
                            </div>
                            <div className="relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
                                {/* <img
                                    className="w-56 md:w-full lg:h-full lg:w-auto lg:max-w-none"
                                    src={teamwork}
                                    alt=""
                                /> */}
                                <Lottie options={
                                    {
                                        loop: true,
                                        autoplay: true,
                                        animationData: freelancingAnimation
                                    }
                                } />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-white">
                    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                        <p className="text-center text-base font-semibold uppercase text-gray-600 tracking-wider">
                            Follow Game Changing Companies
                        </p>
                        <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
                            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                                <img
                                    className="max-h-12"
                                    src={airbnb}
                                    alt="Workcation"
                                />
                            </div>
                            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                                <img className="max-h-12" src={uber} alt="Mirage" />
                            </div>
                            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                                <img className="max-h-12" src={dropbox} alt="Tuple" />
                            </div>
                            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                                <img className="max-h-12" src={slackLogo} alt="Laravel" />
                            </div>
                            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                                <img
                                    className="max-h-12"
                                    src={atlassian}
                                    alt="StaticKit"
                                />
                            </div>
                            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                                <img
                                    className="max-h-12"
                                    src={netflix}
                                    alt="Statamic"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* map section with stats */}
                <div className="bg-gray-50 pt-12 sm:pt-16">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="max-w-4xl mx-auto text-center">
                            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                                A global real estate portfolio to scale your business
                            </h2>
                            <p className="mt-3 text-xl text-gray-500 sm:mt-4">
                                Global Locations
                            </p>
                        </div>
                    </div>
                    <div className="mt-10 pb-12 bg-white sm:pb-16">
                        <div className="relative">
                            <div className="absolute inset-0 h-1/2 bg-gray-50" />
                            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                <div className="max-w-4xl mx-auto">
                                    <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
                                        <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                                            <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">Locations</dt>
                                            <dd className="order-1 text-5xl font-extrabold text-blue-600">634</dd>
                                        </div>
                                        <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                                            <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">Cities</dt>
                                            <dd className="order-1 text-5xl font-extrabold text-blue-600">134</dd>
                                        </div>
                                        <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                                            <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">Countries</dt>
                                            <dd className="order-1 text-5xl font-extrabold text-blue-600">36</dd>
                                        </div>
                                    </dl>
                                </div>
                                <img
                                    className="pt-10 md:w-full lg:h-25 lg:w-50 lg:max-w-none"
                                    src={map}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>


                {/* Testimonial/stats section */}
                <div className="relative mt-20 col-start-2">
                    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
                        <div className="relative sm:py-16 lg:py-0">
                            <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
                                <div className="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72" />
                                <svg
                                    className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
                                    width={404}
                                    height={392}
                                    fill="none"
                                    viewBox="0 0 404 392"
                                >
                                    <defs>
                                        <pattern
                                            id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                                            x={0}
                                            y={0}
                                            width={20}
                                            height={20}
                                            patternUnits="userSpaceOnUse"
                                        >
                                            <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                        </pattern>
                                    </defs>
                                    <rect width={404} height={392} fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
                                </svg>
                            </div>
                            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
                                {/* Testimonial card*/}
                                <div className="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
                                    <img
                                        className="absolute inset-0 object-cover h-full w-full "
                                        src={office}
                                        alt=""
                                    />
                                    <div className="absolute inset-0 bg-blue-500 mix-blend-multiply" />
                                    <div className="absolute inset-0 bg-gradient-to-t from-blue-600 via-blue-600 opacity-90" />
                                    <div className="relative px-8">
                                        <blockquote className="mt-8">
                                            <div className="relative text-lg font-medium text-white md:flex-grow">
                                                <svg
                                                    className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-blue-400"
                                                    fill="currentColor"
                                                    viewBox="0 0 32 32"
                                                    aria-hidden="true"
                                                >
                                                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                                                </svg>
                                                <p className="relative">
                                                    We are going to save thousands with Sacha staying in the same office,this is a no brainer for small businesses.
                                                </p>
                                            </div>

                                            <footer className="mt-4">
                                                <p className="text-base font-semibold text-blue-200">Daniel Rosemond, CEO of GoldLogistics</p>
                                            </footer>
                                        </blockquote>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
                            {/* Content area */}
                            <div className="pt-12 sm:pt-16 lg:pt-20">
                                <h2 className="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl">
                                    Flexibility and performance
                                </h2>
                                <div className="mt-6 text-gray-500 space-y-6">
                                    <p className="text-lg">
                                        Let your employees work near home, decentralize your workforce or even create a hub for collaboration.
                                    </p>
                                </div>
                            </div>

                            {/* Stats section */}
                            <div className="mt-10">
                                <dl className="grid grid-cols-2 gap-x-4 gap-y-8">
                                    {stats.map((stat) => (
                                        <div key={stat.label} className="border-t-2 border-gray-100 pt-6">
                                            <dt className="text-base font-medium text-gray-500">{stat.label}</dt>
                                            <dd className="text-3xl font-extrabold tracking-tight text-gray-900">{stat.value}</dd>
                                        </div>
                                    ))}
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo cloud section */}
                <div className="mt-32">
                    <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
                        <div className="lg:grid lg:grid-cols-2 lg:gap-24 lg:items-center">
                            <div>
                                <h2 className="text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                                    Go All-Access or Dedicated
                                </h2>
                                <p className="mt-6 max-w-3xl text-lg leading-7 text-gray-500">
                                    Rather you chose to be dynamic or static, we've got you covered and can get you a discount.
                                </p>
                            </div>
                            <div className="mt-12">
                                {/* <img
                                    className="w-100 md:w-full lg:h-full lg:w-auto lg:max-w-none"
                                    src={coworking}
                                    alt=""
                                /> */}
                                <Lottie options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: coworkingAnimation
                                }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-white">
                    <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
                        <div className="max-w-3xl mx-auto text-center">
                            <h2 className="text-3xl font-extrabold text-gray-900">Just a taste of the perks</h2>
                            <p className="mt-4 text-lg text-gray-500">
                                Some of the many perks with a membership.
                            </p>
                        </div>
                        <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-3 lg:gap-x-8">
                            {features.map((feature) => (
                                <div key={feature.name} className="relative">
                                    <dt>
                                        <FontAwesomeIcon icon='check' className="absolute h-6 w-6 text-green-500" size='2x' />
                                        <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                                    </dt>
                                    <dd className="mt-2 ml-9 text-base text-gray-500">{feature.description}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>


                {/* <div className="bg-gray-100">
                    <div className="pt-12 sm:pt-16 lg:pt-20">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                            <div className="text-center">
                                <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl lg:text-5xl">Simple no-tricks pricing</h2>
                                <p className="mt-4 text-xl text-gray-600">
                                    If you're not satisfied, contact us within the first 14 days and we'll send you a full refund.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-8 bg-white pb-16 sm:mt-12 sm:pb-20 lg:pb-28">
                        <div className="relative">
                            <div className="absolute inset-0 h-1/2 bg-gray-100" />
                            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                <div className="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
                                    <div className="flex-1 bg-white px-6 py-8 lg:p-12">
                                        <h3 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">Membership</h3>
                                        <p className="mt-6 text-base text-gray-500">
                                            Get the best results for your business, without the cost of a traditional lease
                                        </p>
                                        <div className="mt-8">
                                            <div className="flex items-center">
                                                <h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-blue-600">
                                                    What's included
                                                </h4>
                                                <div className="flex-1 border-t-2 border-gray-200" />
                                            </div>
                                            <ul role="list" className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
                                                {includedFeatures.map((feature) => (
                                                    <li key={feature} className="flex items-start lg:col-span-1">
                                                        <div className="flex-shrink-0">
                                                        </div>
                                                        <p className="ml-3 text-sm text-gray-700">{feature}</p>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
                                        <p className="text-lg leading-6 font-medium text-gray-900">Per employee membership</p>
                                        <div className="mt-4 flex items-center justify-center text-5xl font-extrabold text-gray-900">
                                            <span className=' line-through font-smaller mr-1 text-2xl text-gray-500'>$299</span>
                                            <span>$235</span>
                                            <span className="ml-3 text-xl font-medium text-gray-500">month*</span>
                                        </div>
                                        <p className="font-medium text-gray-500 max-w-42">$225 WeWork All Access membership +</p>
                                        <p className="font-medium text-gray-500 max-w-42">$10 fee</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}


                {/* CTA section */}
                <div className="relative mt-24 sm:mt-32 sm:py-16">
                    <div aria-hidden="true" className="hidden sm:block">
                        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50 rounded-r-3xl" />
                        <svg className="absolute top-8 left-1/2 -ml-3" width={404} height={392} fill="none" viewBox="0 0 404 392">
                            <defs>
                                <pattern
                                    id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
                                    x={0}
                                    y={0}
                                    width={20}
                                    height={20}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width={404} height={392} fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)" />
                        </svg>
                    </div>
                    <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                        <div className="relative rounded-2xl px-6 py-10 bg-blue-500 overflow-hidden shadow-xl sm:px-12 sm:py-20">
                            <div aria-hidden="true" className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
                                <svg
                                    className="absolute inset-0 h-full w-full"
                                    preserveAspectRatio="xMidYMid slice"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 1463 360"
                                >
                                    <path
                                        className="text-blue-400 text-opacity-40"
                                        fill="currentColor"
                                        d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
                                    />
                                    <path
                                        className="text-blue-600 text-opacity-40"
                                        fill="currentColor"
                                        d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
                                    />
                                </svg>
                            </div>
                            <div className="relative">
                                <div className="sm:text-center">
                                    <h2 className="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
                                        Join us and upgrade your office game
                                    </h2>
                                    <p className="mt-6 mx-auto max-w-2xl text-lg text-blue-100">
                                        Sign up for a representative to reach out to you to understand your needs and help you join the group.
                                    </p>
                                </div>
                                <form onSubmit={e => addEmailToWaitlist(e)} className="mt-12 sm:mx-auto sm:max-w-lg sm:flex" id='second'>
                                    <div className="min-w-0 flex-1">
                                        <label htmlFor="cta_email" className="sr-only">
                                            Email address
                                        </label>
                                        <input
                                            id="cta_email"
                                            type="email"
                                            className="block w-full border border-transparent rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-500"
                                            placeholder="Enter your email"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="mt-4 sm:mt-0 sm:ml-3">
                                        <button
                                            type="submit"
                                            className="block w-full rounded-md border border-transparent px-5 py-3 bg-gray-900 text-base font-medium text-white shadow hover:bg-black focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-500 sm:px-10"
                                        >
                                            Sign up
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            {/* Footer section */}
            {/* <footer className="mt-24 bg-gray-900 sm:mt-12">
                <div className="mx-auto max-w-md py-12 px-4 overflow-hidden sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                    <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
                        {footerNavigation.main.map((item) => (
                            <div key={item.name} className="px-5 py-2">
                                <a href={item.href} className="text-base text-gray-400 hover:text-gray-300">
                                    {item.name}
                                </a>
                            </div>
                        ))}
                    </nav>
                    <div className="mt-8 flex justify-center space-x-6">
                        
                    </div>
                    <p className="mt-8 text-center text-base text-gray-400">&copy; 2021 redezio, Inc. All rights reserved.</p>
                </div>
            </footer> */}

            <Footer />
        </div>
    )
}