import React from 'react'
import Header from '../../components/splash/Header'


export default function TeamScreen(){

    return (
        <Header/>

    )



}