import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

export default function Navigation({ selling }) {

    const [openMenu, setOpenMenu] = useState(false)

    function toggleMenu(){
        setOpenMenu(!openMenu)
    }

    return (
        <div className='bg-white'>
            <header>
                <div className="relative bg-white">
                    <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
                        <div className="flex justify-start lg:w-0 lg:flex-1">
                            <Link to="/">
                                <FontAwesomeIcon icon='building' className="text-gray-500 hover:text-gray-900" size='2x' />
                            </Link>
                        </div>
                        <div className="-mr-2 -my-2 md:hidden">
                            <button type="button" className={'bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500'} aria-expanded="false" onClick={()=> toggleMenu()}>
                                <span className="sr-only">Open menu</span>
                                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                                </svg>
                            </button>
                        </div>
                        <nav className="hidden md:flex space-x-10">
                           
                                        <Link to='/' className="text-base font-medium text-gray-500 hover:text-gray-900">
                                            Looking for Space
                                        </Link>
                                        <Link to='/selling' className="text-base font-medium text-gray-500 hover:text-gray-900">
                                            Become a Host
                                        </Link>
        
                        </nav>
                        <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                            <Link to="/login" className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">
                                Host Sign In
                            </Link>
                            <Link to="/contact" className={`ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-500`}>
                                Host Sign Up
                            </Link>
                        </div>
                    </div>

                    {/* <!--
        Mobile menu, show/hide based on mobile menu state.

        Entering: "duration-200 ease-out"
          From: "opacity-0 scale-95"
          To: "opacity-100 scale-100"
        Leaving: "duration-100 ease-in"
          From: "opacity-100 scale-100"
          To: "opacity-0 scale-95"
      --> */}
                    <div className={`absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden ${openMenu ? '' :  'hidden'}`}>
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                            <div className="pt-5 pb-6 px-5">
                                <div className="flex items-center justify-between">
                                    <div>
                                        {/* <img className="h-8 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-blue-600-to-blue-600.svg" alt="Workflow" /> */}
                                        <FontAwesomeIcon icon='building' className="text-gray-900 hover:text-gray-700" size='2x' />
                                    </div>
                                    <div className="-mr-2">
                                        <button type="button" className={`bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset ${selling ? 'focus:ring-blue-500': 'focus:ring-blue-500'}`}onClick={()=> toggleMenu()}>
                                            <span className="sr-only">Close menu</span>
                                            {/* <!-- Heroicon name: outline/x --> */}
                                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="py-6 px-5">
                                <div className="grid grid-cols-2 gap-4">
                                    {selling ?
                                        <>
                                            <Link to="/" className="text-base font-medium text-gray-900 hover:text-gray-700">
                                                Looking for Flex Space
                                        </Link>
                                        <Link to="/contact" className="text-base font-medium text-gray-900 hover:text-gray-700">
                                                Contact Us
                                        </Link>
                                        </> :
                                        <>
                                            <Link to="/pricing" className="text-base font-medium text-gray-900 hover:text-gray-700">
                                                Pricing
                                            </Link>

                                            <Link to="/selling" className="text-base font-medium text-gray-900 hover:text-gray-700">
                                                Become a Host
                                            </Link>

                                            <Link to="/contact" className="text-base font-medium text-gray-900 hover:text-gray-700">
                                                Contact Us
                                            </Link>
                                        </>
                                    }
                                </div>
                                <div className="mt-6">
                                    <Link href="/contact" className={`w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-500`}>
                                        Host Sign Up
                                    </Link>
                                    <p className="mt-6 text-center text-base font-medium text-gray-500">
                                        Existing customer?
                                    <Link to="/contact" className="text-gray-900">
                                            Host Sign In
                                    </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    )
}