import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import './styles/App.css';
import firebase from 'firebase/app'
import 'firebase/auth'
import AddIcons from "./utils/FontAwesome";


import SubmissionScreen from "./screens/splash/SubmissionScreen";

import TermsScreen from "./screens/legal/TermsScreen";
import SpaceUseScreen from "./screens/legal/SpaceUseScreen";
import HostTermsScreen from "./screens/legal/HostTermsScreen";
import PrivacyScreen from "./screens/legal/PrivacyScreen";

import HealthScreen from "./screens/splash/HealthScreen";
import { useSelector } from "react-redux";
import AppWaitlist from "./screens/AppWaitlist";
import SlackScreen from "./screens/splash/SlackScreen";
import MembershipScreen from "./screens/splash/MembershipScreen";
import HomeScreen from "./screens/splash/HomeScreen";
import TeamScreen from "./screens/splash/Team";

export default function App() {
  const [signedIn, setSignedIn] = useState(null)
  const { user } = useSelector(state => state)
  const [hasUserRegistered, setHasUserRegistered] = useState(false)
  const history = useHistory()

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user && user.email) {
        //  I think I should consider loading more of the data at this point...
        setSignedIn(true)
        if (!hasUserRegistered) {
          history.push('/createuser')
        }
      } else {
        firebase.auth().signInAnonymously()
        setSignedIn(false)
      }
    })
  }, [])

  AddIcons();

  useEffect(() => {
    setHasUserRegistered(user.name && user.jobTitle && user.companyName)
  }, [user])


  function LegalLinks({ signedIn }) {
    return (
      <>
        <Route exact path={`${signedIn ? '/legal' : ''}/space-use`}>
          <SpaceUseScreen />
        </Route>
        <Route exact path={`${signedIn ? '/legal' : ''}/privacy`}>
          <PrivacyScreen />
        </Route>
        <Route exact path={`${signedIn ? '/legal' : ''}/host-terms`}>
          <HostTermsScreen />
        </Route>
        <Route exact path={`${signedIn ? '/legal' : ''}/terms`}>
          <TermsScreen title='Terms of Use' />
        </Route>
      </>
    )
  }

  return (
    <Router>
      <Switch>
        <Route path='/submission'>
          <SubmissionScreen />
        </Route>
        <Route path='/health'>
          <HealthScreen />
        </Route>
        <Route path='/waitlist'>
          <AppWaitlist />
        </Route>
        <Route exact path='/slack'>
          <SlackScreen />
        </Route>
        <Route exact path='/membership'>
          <MembershipScreen />
        </Route>
        <Route exact path='/team'>
          <TeamScreen/>
        </Route>
        <Route exact path='/'>
          <HomeScreen />
        </Route>
        <LegalLinks />
      </Switch>
    </Router>
  )
}