import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngellist, faFacebook, faLinkedin, faStripe, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faArrowCircleUp, faBell, faBuilding, faCalendar, faChair, faCheckCircle, faCog, faCreditCard, faCubes, faDoorClosed, faDoorOpen, faExchangeAlt, faFolderOpen, faHome, faImages, faLaptopHouse, faList, faLock, faMapMarkerAlt, faMoneyBillWave, faPlus, faQuestionCircle, faSignOutAlt, faSitemap, faSpinner, faTimes, faTimesCircle, faTrash, faUserCircle, faUsers, faUserShield, faStar, faCheck, faBars, faTools, faSearch, faChartLine, faAtom } from '@fortawesome/free-solid-svg-icons'

//https://fontawesome.com/how-to-use/on-the-web/using-with/react

//have to add every icon name here before it can be seen in the site

export default function AddIcons(){

    library.add(
        faStar,
        faFacebook,
        faTwitter,
        faLinkedin,
        faLock, 
        faBuilding, 
        faLaptopHouse, 
        faBell, 
        faHome, 
        faCog, 
        faUsers, 
        faList, 
        faCreditCard, 
        faQuestionCircle, 
        faUserShield, 
        faTimes, 
        faMapMarkerAlt,
        faCubes,
        faArrowCircleUp,
        faSitemap,
        faMoneyBillWave,
        faExchangeAlt,
        faDoorClosed,
        faHome,
        faLaptopHouse, 
        faBell,
        faCreditCard,
        faCog,
        faQuestionCircle,
        faUserShield,
        faCalendar, 
        faSignOutAlt, 
        faPlus,
        faImages,
        faTrash,
        faSpinner,
        faUserCircle,
        faFolderOpen,
        faAngellist,
        faDoorOpen,
        faTimesCircle,
        faCheckCircle,
        faCheck,
        faStripe,
        faChair,
        faBars,
        faSearch,
        faTools,
        faChartLine,
        faAtom
        )
}