import React from 'react'
import TextSplitter from '../../components/TextSplitter'

export default function PrivacyScreen() {

    const legalText = `redezio, Inc. and our affiliated companies (see Section 4 below) (“redezio” or “we” or “us” or “our”) recognizes the need to protect the privacy of the personal information you provide to redezio with respect to your access and use of redezio’s website (www.sacha.app) (the “Website”) or through redezio’s mobile and web applications (the Website and such mobile and web applications being collectively referred to as the “Service”).

    Therefore, we have adopted this privacy policy (the “Privacy Policy”), which sets forth, among other things, the type of information that will be collected, the purpose and use of the collected information, and your rights with regard to the collected information. This Privacy Policy governs how we collect and use your personal information wherever you use our Service. By accessing the Service, you are consenting to the collection and the use of your information by redezio, but only to the extent described herein. Should you wish to revoke your consent, you may do so by contacting the local redezio entity in your country, information on which is set out at Section 4 below.

    redezio may make modifications, deletions and/or additions to this Privacy Policy (“Changes”) at any time. Changes will be effective: (i) thirty (30) days after redezio provides notice of the Changes, whether such notice is provided through the Service user interface, is sent to the email address associated with your account or otherwise; or (ii) when you opt-in or otherwise expressly agree to the Changes or a version of this Privacy Policy incorporating the Changes, whichever comes first.

    1. Protection of Your Information

    We are accountable for the information in our custody. Therefore, when we collect or use your information, we will utilize commercially reasonable safeguards to ensure its protection. It should be noted that no security procedure is currently 100% effective. Should any breach of your Personal Information occur, we will inform you as soon as reasonably possible, as required by applicable law.

    2. Type and Purpose of Collection

    We collect information at various points in the Service to facilitate its use by our customers. Specifically, two types of information are collected:

    Non-Personal Information: Upon accessing the Service, certain non-personal information will be automatically collected without your knowledge or consent, such as your IP address, location data (which is anonymous and where you log in through the Service you will be asked if you consent to the Service accessing your location, which can be updated at any time in your device settings) and the referring website (“Non-Personal Information”). We use Non-Personal Information to examine our traffic and to view how our customers use the Service. This type of information will not allow you to be personally identified although we might be able to associate it with your account. For example, we use “cookies”, which contain only certain statistical information. For more information on cookies please see Section 11 below.

    Identifying Personal Information: To utilize some portions of the Service, you must first provide personal information that will allow you to be identified (“Personal Information”). This type of information will not be collected without your consent. The purposes of the collection of Personal Information are the following:

    To fulfill your order;
    To send you an order confirmation;
    iii. To administer your account;
    To send you push notifications or SMS messages when you are using the Service in relation you use of the services we provide, for example, to provide a code in order to access a location;
    To send you our newsletter provided you have not unsubscribed. You may opt out of such newsletter through using the unsubscribe link or by contacting us at ryan@sacha.app;
    To establish a relationship with you;
    vii. To facilitate your service with our affiliated service providers;
    viii. To anticipate and resolve problems with your service;
    To understand your needs and desires vis-à-vis the Service;
    To update you on changes to our services or products, including new promotions provided that you have not unsubscribed. You may opt out of such notifications through the unsubscribe link or by contacting us at ryan@sacha.app.
    We expressly acknowledge that we will not use your Personal Information for any other purposes without your consent. Further, we will only collect Personal Information to the extent necessary for the above mentioned purposes. The Personal Information we collect will vary depending on how you are using the Service, but may include, without limitation: (i) your name, email address, zip code, mailing address and phone number; (ii) credit card information and charge history; (iii) unique identifiers such as user name, account number and password; (iv) preferences information such as product or content interests, communication or marketing preferences; (v) device information and data; (vi) reservation history and coupon usage history; and (vii) Google, Twitter, LinkedIn, and Facebook identity.
    Additionally, Personal Information may be used and disclosed to the extent redezio may deem reasonably necessary to enforce the terms of any agreement between you and redezio, or to protect the rights, property or safety of any person or entity.

    3. Right to Examine Information

    You have the right to examine any of your Personal Information that we collect. Should you wish to examine such information, please send us a written request to ryan@sacha.app. We reserve the right to charge you a reasonable administrative fee to access your information, as permitted by applicable law. In certain cases, we may not be able to provide you with access to all of your Personal Information (ex: if the information also pertains to the Personal Information of another user).
    4. Withdrawal of Consent

    You may withdraw your consent to the collection of Personal Information at any time by sending a written request to the relevant redezio entity (please see below). Upon receiving notice that you have revoked your consent, we will stop using your Personal Information within a reasonable time, which will vary depending on what information we have collected and for what purpose. Please note that we will send you an email confirmation upon receipt of your request. Therefore, if you do not receive a confirmation email, please contact us again with your request. If you do choose to withdraw such consent, your experience on the Service may be diminished, or your ability to choose some of the options on the Service may be limited.
    redezio, Inc, 2407 S Congress Ave Suite E, USA
    info@sacha.app

    5. Sharing Information

    We will not sell, rent or disclose to outside parties the information we collect and save except that we may share the collected information with other parties as follows:

    Affiliated Service Providers: We have agreements with various affiliated service providers to facilitate the functioning of the Service, with whom we may share the information we have collected. For example, we may share your credit card information with the credit card service provider to process your purchase. All administrative service providers that we use are required to have the same level of privacy protection as we have, and therefore we expect that your information will be handled with the same level of care that we employ. Additionally, for example, we may use analytic services (such as, without limitation, Mixpanel).

    Where required by law: We may share the collected information where required by law, specifically in response to a demand from government authorities where such demand meets the legal requirements.

    Statistical Analysis: We may share Non-Personal Information and aggregated information with third parties, including but not limited to for advertising or marketing purposes. No Personal Information will be shared in this manner.

    Transactions: In connection with, or during negotiations of, any merger, sale of company assets, financing or acquisition, or in any other situation where Personal Information may be disclosed or transferred as one of our business assets.

    redezio company and redezio offices and operations: We may share Personal Information and Non-Personal Information with our company or with our offices and operations based outside your local country in order to provide you with our services and for our own operational processes.

    6. External Links

    The Service contains links and references to other websites. We are not responsible for the collection, use and disclosure of information, or the privacy practices of such websites, and we expressly disclaim any liability relating thereto.

    7. International Transfer

    Both Personal Information and Non-Personal Information you submit via the Service is sent to our company and will processed in the USA and stored on secure servers located in the USA. Such information may also be transferred by us to our offices and third parties mentioned in the circumstances (see Section 5 above). The countries concerned may not have similar data protection laws to your country. Where we transfer your information out of your jurisdiction we will take reasonable steps to ensure that your privacy rights continue to be protected. By submitting your information to the Service, you agree to this storing, processing and transfer.

    8. Payment processing

    Payment details you provide will be encrypted using secure sockets layer (SSL) technology before they are submitted over the internet. Payments made on the Service are made through our payment gateway provider, Stripe. You will be providing credit or debit card information directly to Stripe which operates a secure server to process payment details, encrypting your credit/debit card information and authorising payment. Information which you supply to Stripe is not within our control and is subject to Stripe's own privacy policy and terms and conditions.

    9. Terms of Service

    This Privacy Policy is incorporated into and forms part of the Terms of Service, which outlines the terms and conditions you agree to when accessing and using the Service, and which can be found here: www.sacha.app/legal/terms-of-use.

    10. Persons Under 18

    The Service are not marketed toward persons under the age of 18. If redezio discovers that it has inadvertently collected Personal Information about individuals under the age of 18, it will promptly delete such information.

    11. Cookies and similar technologies

    When you interact with the Service, we try to make that experience simple and meaningful. When you use our Service, our web server sends a cookie to your computer or mobile device (as the case may be). Cookies are small pieces of information which are issued to your computer or mobile device (as the case may be) when you visit a website or access or use a mobile application and which store and sometimes track information about your use of a website or application (as the case may be). A number of cookies we use last only for the duration of your web session or Service session and expire when you close your browser or exit the Service. Other cookies are used to remember you when you return to the Service and will last for longer.

    Some of the cookies used by the Service are set by us, and some are set by third parties who are delivering services on our behalf.
    Most web and mobile device browsers automatically accept cookies but, if you prefer, you can change your browser to prevent that or to notify you each time a cookie is set. You can also learn more about cookies by visiting www.allaboutcookies.org which includes additional useful information on cookies and how to block cookies using different types of browser or mobile device. Please note, however, that by blocking or deleting cookies used on the Service, you may not be able to take full advantage of the Service.

    The Service use cookies and similar technologies to cookies to collect information about your access and use of the Service. You may also update your privacy settings on your device by setting the ‘Limit Ad Tracking’ and Diagnostics and Usage setting property located in the settings screen of your Apple iPhone or iPad, or by resetting your Android ID through apps that are available in the Play Store. You may also stop information collection by uninstalling the Service on your device and you can use the standard uninstall process available as part of your device for this purpose.

    Some information on the types of cookies we use and how we use them is set out below by type of cookie and followed by function description:

    Essential: Allow you to navigate and use all the features provided by our Service

    Functional Cookies: These cookies customize elements of the promotional layout and/or content of the pages of the Service and remember that you have visited us before; this means we can identify the number of unique visitors we receive. This allows us to make sure we have enough capacity for the number of users that we get. So when you use our Service, we remember you and your preferences and information you have submitted to us to provide you with a customized experience. For example, remembering your 'home city'.
    Performance and Analytics: Performance cookies let us collect information about how you use the Service. They collect anonymous statistical information about how you use the Service (including how long you spend on the Service) and where you have come to the Service from, so that we can improve the Service and learn which functions of the Service are most popular with users. This information does not include Personal Information although we might be able to associate such information with user accounts once we receive it from the third party.

    Advertising and Targeting: These cookies collect information about the pages you visit online. They gather information about the pages that you visit or the functions of the Service you use, and also other information about other websites that you visit, so as to place you in a “market segment”. This information is only collected by reference to the IP address or unique device identifier that you are using (the applicable cookie identifier) and also can include information about the county and city you are in, together with the name of your internet service provider. This information is then used to place interest-based advertisements on the Service which it is believed will be relevant to your market segment. This may result in you seeing adverts for our Service when you visit other websites.

    For more information about this type of interest based advertising, and about how to turn this feature off please visit http://www.youronlinechoices.com/uk/ or follow the link to the website of our advertising partner shown below.
    We also use third parties, for example, Google Analytics, to analyse statistical information from users of the Service. You can understand Google Analytics further here https://www.google.com/intl/en/analytics/learn/privacy.html and learn about how to manage Google Analytics settings here https://tools.google.com/dlpage/gaoptout.

    12. Social Media

    You may log-on to your account with us by using social media plug-ins from social media platforms such as Twitter and Facebook. If you choose to log in via a social media platform, our Service will connect to the servers of the social media platform. We will receive your basic profile information as directed by the relevant social media platform when you log in. You will also have the option to share features of our Service with the relevant social media platform as you wish. Visit the privacy policy of the relevant social media platform provider to learn more about your rights and choices.

    Should you have any questions or comments concerning this Privacy Policy, please do not hesitate to contact us at ryan@sacha.app.

    13. Last Revised

    We published this Terms of Use as of Jan 17th, 2021.`

    return (
        <div className='overflow-y-auto mx-auto'>
            <div className='bg-white max-w-3xl m-10 p-3 rounded shadow'>
                <h4 className='text-2xl mb-5'>
                    Privacy Policy
        </h4>
                <TextSplitter string={legalText} />
            </div>
        </div>
    )
}