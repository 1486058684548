import React from 'react'
import Lottie from 'react-lottie'

export default function BigFeature({topTitle, title, description, lottie, id}) {
    return (
      <div  id={id} className="relative bg-gray-50 pt-16 overflow-hidden sm:pt-24 lg:pt-32">
        <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
          <div>
            <h2 className="text-base font-semibold tracking-wider text-blue-600 uppercase">{topTitle}</h2>
            <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
              {title}
            </p>
            <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
              {description}
            </p>
          </div>
          <div className="mt-12 mb-10">
            {/* <img
              className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
              src="https://tailwindui.com/img/component-images/top-nav-with-multi-column-layout-screenshot.jpg"
              alt=""
            /> */}
            <div className=" lg:max-w-6xl ">
                <Lottie options={
                    {
                        autoplay: true,
                        loop: true,
                        animationData: lottie
                    }
                }
                />
            </div>
          </div>
        </div>
      </div>
    )
  }