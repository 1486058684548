import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Footer from '../../components/splash/Footer'
import Navigation from '../../components/splash/Navigation'

export default function HealthScreen() {

    return (
        <div className='bg-white'>
            <Navigation />
            <div className=' max-w-6xl mx-auto mt-10 mb-10'>
                <h1 className='text-black text-center'>Health & Safety</h1>
                <p className=' text-2xl text-gray-500 mt-5 text-center'>
                    Sacha cares about your well being and wants to ensure that you feel safe while visiting our spaces.
                    That’s why we’ve partnered with our spaces to ensure your space is clean.
                </p>

                <div className='mt-10'>
                    <div className='border border-gray-200 rounded max-w-3xl mt-5 mx-auto'>
                        <h2 className='text-2xl text-center mt-5'>The Basics</h2>
                        <p className='text-center text-gray-500 '>Required for Every Space Every Day</p>
                        <div className='mt-5'>
                            <ul className='ml-10 pt-5 pb-5'>
                                <li className='p-5 flex-row flex items-center'>
                                    <FontAwesomeIcon icon='check-circle' color='blue' size='s' />
                                    <p className='ml-3'>
                                        Daily cleaning
                                </p>
                                </li>
                                <li className='p-5 flex-row flex items-center'>
                                    <FontAwesomeIcon icon='check-circle' color='blue' size='s' />
                                    <p className='ml-3'>
                                        Masks Required
                                </p>
                                </li>
                                <li className='p-5 flex-row flex items-center'>
                                    <FontAwesomeIcon icon='check-circle' color='blue' size='s' />
                                    <p className='ml-3'>
                                        Social Distancing
                                </p>
                                </li>
                                <li className='p-5 flex-row flex items-center'>
                                    <FontAwesomeIcon icon='check-circle' color='blue' size='s' />
                                    <p className='ml-3'>
                                        Safety Signage
                                </p>
                                </li>
                                <li className='p-5 flex-row flex items-center'>
                                    <FontAwesomeIcon icon='check-circle' color='blue' size='s' />
                                    <p className='ml-3'>
                                        Hand Sanitizer Provided
                                </p>
                                </li>
                                <li className='p-5 flex-row flex items-center'>
                                    <FontAwesomeIcon icon='check-circle' color='blue' size='s' />
                                    <p className='ml-3'>
                                        HVAC Filters and Fresh Air Circulation
                                </p>
                                </li>

                                <li className='p-5 flex-row flex items-center'>
                                    <FontAwesomeIcon icon='check-circle' color='blue' size='s' />
                                    <p className='ml-3'>
                                        Furniture Disinfected
                                </p>
                                </li>
                                <li className='p-5 flex-row flex items-center'>
                                    <FontAwesomeIcon icon='check-circle' color='blue' size='s' />
                                    <p className='ml-3'>
                                        Hand Sanitizer Provided
                                </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-10'>
                <div className='bg-gray-100 p-10'>
                    <div>
                        <h1 className='text-center'>For More Information</h1>
                        <div className='grid grid-cols-2 max-w-6xl mx-auto mt-5 pb-10'>
                            <div className=' col-span-1 m-3'>
                                <p className='text-center'>For more information if you are looking to rent a space please <a href='mailto:info@sacha.app' className=' text-blue-700 hover:text-blue-500'>reach out to our team</a> and we would be happy to answer any of your questions</p>

                            </div>
                            <div className=' col-span-1 m-3'>
                                <p className='text-center'>If you are looking to become a host and can follow these guidelines then please <a href='mailto:info@sacha.app' className=' text-blue-700 hover:text-blue-500'>reach out to us</a>.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}