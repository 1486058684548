import { 
    createSlice,
    createSelector,
  } from '@reduxjs/toolkit'
  
  const groupSlice = createSlice({
    name: 'group',
    initialState: {
        deskCount: 0,
        roomCount: 0,
        totalRevenue: 0
    },
    reducers: {
      addGroup: (state, action) => state = action.payload,
      addDeskCount(state, action) {
        state.deskCount = action.payload
      },
      addRoomCount(state, action){
         state.roomCount = action.payload
      }
    }
  })
  
  export const { addDeskCount, addRoomCount, addGroup } = groupSlice.actions
  
  export const getDeskCount = createSelector(state => state.deskCount)
  export const getRoomCount = createSelector(state => state.roomCount)
  export const getTotalRevenue = createSelector(state => state.totalRevenue)
  
  export default groupSlice.reducer