import React from 'react'
import TextSplitter from '../../components/TextSplitter'

export default function HostTermsScreen() {

    const legalText = `The following additional terms and conditions (the “Host Terms”) are to be read in conjunction with, and as an integral part of the Terms of Use and Privacy Policy and specifically govern the use of the Service by Hosts (“You” or the “Host”). The Terms of Use, Privacy Policy, and these Host Terms (collectively, the “Terms and Policies”) together govern each Host’s use of the Service. Any capitalized terms used but not defined in these Host Terms shall have the meanings ascribed to them in the Terms and Policies, which can be found at www.sacha.app/legal/terms-of-use.

    If You use the Service You represent, warrant and agree that You will abide by these Host Terms and any actions You take in connection with the Service will conform to the rules and regulations set forth therein. You agree to respond promptly and completely to requests from redezio, Inc. (“redezio”) for additional information that redezio deems necessary. redezio may suspend or terminate Your access, and Your company or organization’s access, to the Service and Your account if redezio has reason to believe that You are not authorized to act on behalf of the company or organization for whom You claim to be acting in connection with the Service.
    
    1. Registration and Termination
    
    Host must register as required by the Service. If Host decides to terminate the Service, Host may block additional reservations from being made, and agrees not to terminate Service for the remaining reservations that exist at the time of termination in the Host’s Space(s), or as stipulated by the Terms of Use.

    2. Suitability of Space(s), Communications with Guests
    
    By using the Service, each Host agrees that the Host’s Space(s) and associated common areas, general areas and shared facilities listed on the Service are and will at all times be safe and suitable for the uses anticipated by the Service and reasonably appropriate for the Guest, based on known or anticipated uses of the Space in which the Guest is likely to engage. Hosts will take such actions as are necessary to make Space(s) available to Guests in accordance with the terms of service issued by the Host, and Host will make all such terms and conditions easily available to Guests. Hosts will also maintain communication channels (e.g. telephone numbers, email) that are adequate for all communications with Guests for the purposes anticipated by the Service.
    
    Hosts are solely responsible for all representations that they may make to Guests about the equipment, electronics, lighting, features or services provided by and/or available at Host’s Space(s), including, but not limited to, physical security, HIPAA compliance of systems, and general condition of the premises. You agree that all terms and obligations of such representations are solely made by You (as Host) to Guests and potential Guests, and that redezio will not be liable for any misrepresentation or omission.
    
    3. Insurance and Indemnification
    
    In addition to any other indemnification obligations required by the Agreement, Host agrees to hold harmless, defend and indemnify Company from and against all claims, liabilities, and losses (“Engagement Liabilities”) that may arise from or in the course of Guests’ use of the Space(s) and/or other facilities listed by Host on the Service, including, without limitation, any common areas, shared facilities or Host facilities. Hosts are solely responsible for providing insurance coverage that is sufficient to insure against premises liability and such other claims, liabilities or losses as may arise from Guests’ use of the Space(s), common areas, shared facilities or Host facilities, except as may be limited expressly by the Host in the Host’s terms of service (e.g. limitations with regard to damage to personal property, etc.).
    
    4. Transactions Between Guests and Hosts for Reserving Spaces
    
    redezio offers the Service as a platform to connect Hosts and Guests and facilitate the reserving of Spaces between the Hosts and Guests. You understand and agree that redezio does not act as an insurer, landlord, sub-landlord or as Your contracting agent or real estate broker. If a Guest requests a reservation of Your Space and uses Your Space, any agreement You enter into with such Guest is between You and the Guest and redezio is not a party to that agreement and disclaims any and all representations, warranties or liabilities with respect thereto.
    As a Host, You agree to abide by the following Host policies:
    
    You are solely responsible for maintaining the Space in safe condition and in compliance with all applicable laws, regulations and ordinances (including zoning ordinances) and any applicable building requirements.
    You are solely responsible for ensuring that the Space is clean and ready to use for the Guest, for Guest’s known or anticipated use, at the start of each Reservation period.
    
    You are solely responsible for ensuring that the reservation of the Space through the Service does not violate any lease, tenancy or other agreements, including any master lease, relating to the Space or any building policies.
    If You approve a reservation of Your Space to begin at a specified time or on a specified date, You shall ensure that the Space is available to the Guest at that time and on that date. In the event the Space is not available to the Guest at that time and on that date, You shall make available to the Guest at the originally scheduled time and date another space in the same building of equal or larger size and similar configuration for the same Fee.
    
    If a Guest requests a reservation for a specific Space and Host is not able to provide access to such Space(s) for any reason, then Host is responsible for and will provide to such Guest(s) a refund of the funds actually received by the Host for the Space(s) for such reservation.
    
    Both Guests and Hosts may submit feedback to the Service following the completion of a Space reservation. You acknowledge and agree that other Guests may publicly post ratings and reviews of You or Your Space through redezio, as applicable, and that redezio may, but is not obligated to, monitor such postings and is not responsible in any manner for such content, ratings and reviews, and You hereby release and hold harmless redezio from any and all claims, causes of actions, obligations or liabilities arising from, relating to, or in connection with such ratings and reviews.
    
    5. Payment
    
    If a Host elects to use the payment processing capabilities of the Service, the Host agrees as follows: (a) Host understands that Company has engaged a third party service to provide credit card processing services (“Payment Processor”), and Host consents to the provision of such services by such Payment Processor; and (b) Host understands and agrees that the Company and the Payment Processor will charge certain standard fees for the provision of such payment processing services.
    
    The Service is constituted of Day Passes for select Space zones and Hourly Reservations for select Hourly Resources. As a Host, You agree to be responsible for establishing rates for Hourly Resources You are making available through the Service.
    
    Changes to Daily Rates or Hourly Rates for Reservations will not affect the amount billed for Reservations that have already been booked, even if such Reservations have not yet begun.
    redezio will collect on Your behalf as Your limited payment collection agent the following Guest Payments (the “Guest Payments Collected”) for Reservations, the Guest Payments for Reservations that were (i) completed or (ii) cancelled but not fully refunded under the Cancellation Policy.
    
    redezio will pay You the difference between the Guest Payments Collected and Service Fee due (the "Earnings") on the 1st day of every month for reservations that have concluded, during which time Earnings are held as a Temporary Credit to the Host for the purpose of maintaining sufficient funds for the Host to issue refunds to the Guest in the Host’s dedicated Payment Processor account. If Host’s refund obligations exceed the Temporary Credit, redezio will debit the remainder from the Host’s new Earnings.
    
    The “Service Fee” is set forth in the Service Fee section below. You agree to pay redezio the Service Fee. If the Service Fee exceeds the Guest Payments Collected, redezio will invoice the Host for any amount owed by the Host that exceed US$250 and the Host shall pay such amounts within 30 days after invoicing.
    
    redezio reserves the right to withhold payment to Your account of any amounts otherwise due to redezio under these Host Terms, or any amounts due as a result of a breach of these Host Terms by You, pending redezio's reasonable investigation of such breach. In addition, redezio may withhold or deduct from amounts to be paid to You in connection with these Host Terms any charges, fees or Guest Payments that are disputed or otherwise not paid by a Guest or a credit card company or other payment to the Host, and redezio may charge You (and You shall promptly pay) any such amounts in the event that redezio has already paid such amounts to You.
    
    You agree to pay all applicable taxes, fees or charges imposed by any government entity in connection with Your use, or redezio's provision, of the Service. Specifically, You are responsible for any sales, use or value-added taxes or charges imposed by any government entity in connection with Your use or redezio's provision of the Service.
    To ensure proper payment, You are solely responsible for providing and maintaining accurate contact and payment information associated with Your account, which includes without limitation applicable tax information, and You shall keep complete and accurate records regarding Space usage and payments by Guests. redezio may audit such records upon reasonable notice and at reasonable times to confirm proper payment hereunder and otherwise confirm compliance with these Host Terms.
    
    If redezio believes that it is obligated to obtain tax information and You do not provide this information after being requested to do so, redezio may withhold Your payments until You provide this information or confirm in a manner that is satisfactory to redezio that You are not a person or entity from whom redezio is required to obtain tax information. Any bank fees arising from Your use of the Service, or any error or omission in Your payment information or contact information may be deducted from Your payments. If You dispute any payment, withholding or deduction made hereunder, You must notify redezio in writing within 30 days of any such payment, withholding or deduction; failure to so notify redezio shall result in the waiver by You of any claim relating to any such disputed payment, withholding or deduction.
    
    6. Use by redezio Employees and Contractors
    
    Each Host authorizes redezio support personnel to use the Spaces through the Service as needed for purposes of testing and operational support. Any use of the Spaces through the Service by redezio support personnel as needed for purposes of testing and operational support shall be at no charge.
    
    7. Additional Representations and Warranties
    
    In addition to any other representations and warranties in the Terms of Use, You represent and warrant that:
    any Space You offer for reservation hereunder is free of any known conditions or defects that would pose a hazard to or risk the safety of any Guest; and
    
    You have sufficient rights in and to the Space to participate in the Service as a Host, including any applicable authorizations, consents, or permits, and Your participation in the Service as a Host will not violate or result in the breach of any agreement between You and any third party, including any lease agreement, tenancy, master lease, or other agreement relating to the Space or the Building, including, without limitation, authority for Guests to access and use Common Areas and Shared Facilities.
    
    8. Release
    
    You hereby unconditionally release, indemnify and hold harmless redezio from any loss, liability, claim, demand, damage, costs and expenses, including reasonable attorneys’ fees, which You now have or have ever had against redezio relating in any way to Your use of the Service. You hereby acknowledge that You are aware of California Civil Code Section 1542, which provides as follows:
    
    A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR.
    With full awareness and understanding of the above provisions, You hereby waive any rights You may have under Section 1542, as well as under any other statutes or common law principles of similar effect.
    
    9. Governing Law; Arbitration
    
    THIS SECTION INCLUDES AN ARBITRATION AGREEMENT AND AN AGREEMENT THAT ALL CLAIMS WILL BE BROUGHT ONLY IN AN INDIVIDUAL CAPACITY (AND NOT AS A CLASS ACTION OR OTHER REPRESENTATIVE PROCEEDING). PLEASE READ IT CAREFULLY. You MAY OPT OUT OF THE ARBITRATION AGREEMENT BY FOLLOWING THE OPT OUT PROCEDURE DESCRIBED BELOW.
    
    Informal Process First
    
    You agree that if You have any dispute with redezio relating in any way to these Host Terms or from access to or use of the Service, You will first contact redezio and attempt to resolve the dispute with redezio informally.
    Arbitration (“Arbitration Agreement”)
    
    If we are unable to resolve the dispute informally, we each agree to resolve any claim, dispute, or controversy (excluding claims for injunctive or other equitable relief) arising out of or in connection with or relating to these Host Terms by binding arbitration. If You are an individual You may opt out of this Arbitration Agreement within 30 days of the first of the date You access or use the Service by following the procedure described below.
    
    Arbitration is more informal than a lawsuit in court. There is no judge or jury in arbitration. Instead, the dispute is resolved by a neutral arbitrator. Court review of an arbitration award is limited. Except to the extent the parties agree otherwise, arbitrators can award the same damages and relief that a court can award. You agree that the U.S. Federal Arbitration Act governs the interpretation and enforcement of this provision, and that You and redezio are each waiving the right to a trial by jury or to participate in a class action, to the maximum extent permitted by law. This Arbitration Agreement will survive any termination of these Host Terms.
    
    If you intend to seek arbitration, after following the informal dispute resolution procedure, you must first send written notice to redezio of your intent to arbitrate (“Notice”). The Notice to redezio should be sent by emailing the Notice to clark@sacha.app.
    
    The Notice must (i) describe the nature and basis of the claim or dispute; and (ii) set forth the specific relief sought. The arbitration will be administered by the International Institute for Conflict Prevention and Resolution (“CPR”) under the CPR Rules for Administered Arbitration by a single arbitrator. The arbitration will be conducted in the County of Travis, State of Texas, United States.
    
    Each party will be responsible for paying any CPR, administrative and arbitrator fees (other than the initial filing fees) in accordance with CPR Rules, except that redezio will pay for Your reasonable filing, administrative, and arbitrator fees if Your claim for damages does not exceed $75,000 and is non-frivolous (as measured by the standards set forth in Federal Rule of Civil Procedure 11(b)). Nothing in this Arbitration Agreement shall prevent either party from seeking injunctive or other equitable relief from the courts for matters related to data security, intellectual property, or unauthorized access to the Service.
    
    If You do not want to arbitrate disputes with redezio and You are an individual, You may opt out of this Arbitration Agreement by sending an email to: clark@sacha.app within 30 days of the first of the date You access or use the Service.
    ALL CLAIMS MUST BE BROUGHT IN THE PARTIES’ INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS REPRESENTATIVE IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING, AND, UNLESS WE AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON'S CLAIMS. You AGREE THAT, BY ENTERING INTO THESE HOST TERMS, You AND redezio ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.
    
    If the prohibition against class actions and other claims brought on behalf of third parties contained above is found to be unenforceable, then the entirety of this Arbitration Agreement will be null and void.
    
    If for any reason a claim proceeds in court rather than in arbitration, the dispute will be governed exclusively and irrevocably by the laws of the State of Illinois and the FAA without regard to or application of its conflict of law provisions or Your state or country of residence, and will be brought solely in the federal or state courts located in the County of Cook, State of Illinois, United States. You irrevocably consent to the jurisdiction of and venue in such courts and waive any objection as to inconvenient forum.
    
    10. DEFINITIONS
    
    "Territory" is the United States. These pricing terms apply to Spaces located in the Territory.
    "Coupons" are discounts off of Guest Payments for Reservations that are applied at the time a Guest makes a Reservation. The Coupon reduces the amount of Guest Payments collected from the Guest by redezio by the value of the discount. Coupons may either be redezio Coupons or Space Coupons.
    
    "redezio Coupons" are Coupons issued by redezio that may be applicable to all Spaces or to a subset of Spaces at the sole discretion of redezio. When calculating amounts payable to the Host, redezio will add back the full value of the discount to the Amount Due to the Host for each reservation transaction where a redezio Coupon is redeemed.
    
    "Space Coupons" are Coupons issued by redezio at the request of a Host. Space Coupons may be applicable to all or a subset of the Spaces registered by the Host. When calculating the Service Fee, redezio will reduce the Guest Payments collected as the basis for the Service Fee by the value of the discount for the Space Coupon used.
    
    "Space Marketing Link" is a URL created by redezio for the Space and posted by the Host in the Host’s marketing materials including the Host’s website.
    
    11. Space Codes & Beacons
    The Host may elect to display Codes, such as QR Codes or Beacons, provided by redezio at specific space entrances and exits. The Guest scans these Codes with the redezio Mobile Software to alert the Host and redezio of their space use. redezio manufactures these Codes and will provide them to the Host. The Host agrees to mount and maintain these Codes for the duration of their use of these Codes, and to inform redezio at clark@sacha.app of damage to the Code, if the Code requires replacement, or if the Code needs to be moved.
    
    12. Service Fee
    
    redezio shall receive a service fee of 15% of the overall transaction value, not including taxes, for each transaction fulfilled on the redezio platform, with no end date.
    
    13. Terms & Policies Summary
    
    A summary of redezio legal docs is available at www.sacha.app/legal/terms-of-use.
    
    14. Last Revised
    
    We published these Host Terms as of Feb 5th, 2021.`

    return (
        <div className='overflow-y-auto mx-auto'>
            <div className='bg-white max-w-3xl m-10 p-3 rounded shadow'>
                <h4 className='text-2xl mb-5'>
                    Host Terms of Use
            </h4>
                <TextSplitter string={legalText} />
            </div>
        </div>
    )
}