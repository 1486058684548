import React, { useState, useCallback } from 'react'
import Navigation from '../components/splash/Navigation'
import Footer from '../components/splash/Footer'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Redirect } from 'react-router'
import { submitEmailForWaitlist } from '../utils/api'
import { appinstall } from '../assets'


export default function AppWaitlist() {
    const [email, setEmail] = useState()
    const [submitted, setSubmitted] = useState()
    const { executeRecaptcha } = useGoogleReCaptcha();

    const recaptchaHandler = useCallback(async () => {
        if (!executeRecaptcha) {
            return
        }
        const token = await executeRecaptcha('contact');
        return token

    }, [executeRecaptcha])

    async function submitForm(event) {
        event.preventDefault()

        await recaptchaHandler();

        setSubmitted(true)

        if (email) {
            submitEmailForWaitlist(email)
        }
    }
    if (submitted) {
        if (email) {
            return <Redirect to={{
                pathname:'/submission',
                state: {appInstall: true}
            }} />
        }
    }

    return (
        <div>
            <Navigation />
            <div className='mx-auto max-w-3xl m-10'>
                <img src={appinstall} className=' w-48 m-10 mx-auto' alt='app install' />
                <h1 className='text-center m-5'>Join our Waitlist</h1>
                <p className='text-gray-500 text-center mx-10'>We are putting our finishing touches on the app for you and would love for you to join us once it is out.</p>
                <p className='text-gray-500 text-center mx-10'> Please add your email so we can notify you.</p>

                <form onSubmit={e => submitForm(e)} className="grid grid-cols-1 gap-y-6 sm:grid-cols-1 sm:gap-x-8 m-10">
                    <div className="sm:col-span-1">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Email
                            </label>
                        <div className="mt-1">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                className="py-3 px-4 block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                                placeholder='me@example.com'
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="sm:col-span-1">
                        <button
                            type="submit"
                            className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                            Join Waitlist
                            </button>
                    </div>
                </form>
            </div>
            <Footer />
        </div>
    )
}