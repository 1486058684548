import { 
    createSlice,
    createSelector,
  } from '@reduxjs/toolkit'
  
  const notificationsSlice = createSlice({
    name: 'notifications',
    initialState:  [],
    reducers: {
      addNotifications: (state, action) => state = action.payload,
    }
  })
  
  export const { addNotifications } = notificationsSlice.actions
  
  export const getNotifications = createSelector(state => state.notifications)
  
  export default notificationsSlice.reducer