import { configureStore } from '@reduxjs/toolkit'
import userReducer from './slices/user'
import spaceReducer from './slices/spaces'
import reservationsReducer from './slices/reservations'
import groupReducer from './slices/group'
import paymentReducer from './slices/payment'
import notificationsReducer from './slices/notifications'
import settingsReducer from './slices/settings'

const store = configureStore({
    reducer: {
        user: userReducer,
        spaces: spaceReducer,
        reservations: reservationsReducer,
        group: groupReducer,
        payments: paymentReducer,
        notifications: notificationsReducer,
        settings: settingsReducer
    }
})

store.subscribe(()=> console.log('store', store.getState()))

export default store