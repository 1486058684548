import {
    createSlice,
    createSelector,
  } from '@reduxjs/toolkit'
  
  const settingsSlice = createSlice({
    name: 'settings',
    initialState: {},
    reducers: {
      addSettings: (state, action) =>  state = action.payload
    }
  })
  
  export const { addSettings } = settingsSlice.actions
  
  export const getSettings = createSelector(state => state)
  
  export default settingsSlice.reducer