import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <footer className="bg-gray-50" aria-labelledby="footerHeading">
            <h2 id="footerHeading" className="sr-only">Footer</h2>
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                <div className="xl:grid xl:grid-cols-3 xl:gap-8">
                    <div className="space-y-8 xl:col-span-1">
                        {/* <img className="h-10" src="https://tailwindui.com/img/logos/workflow-mark-gray-300.svg" alt="Company name" /> */}
                        <FontAwesomeIcon icon={'building'} className='h-10 w-10 text-gray-300' size='3x' />
                        <p className="text-gray-500 text-base">
                            {/* Better office, better community, better planet. */}
                            The real estate team for your business
                        </p>
                        <div className="flex space-x-6">
                            <a href='https://www.facebook.com/SachaATX/' target='_blank' rel="noreferrer">
                                <FontAwesomeIcon icon={['fab', 'facebook']} className="text-gray-400 hover:text-gray-500" size='lg' />
                            </a>

                            <a href='https://angel.co/company/redezio' target='_blank' rel="noreferrer">
                                <FontAwesomeIcon icon={['fab', 'angellist']} className="text-gray-400 hover:text-gray-500" size='lg' />
                            </a>

                            <a href='https://www.linkedin.com/company/redezio' target='_blank' rel="noreferrer">
                                <FontAwesomeIcon icon={['fab', 'linkedin']} className="text-gray-400 hover:text-gray-500" size='lg' />
                            </a>
                        </div>
                    </div>
                    <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
                        <div className="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                                    Solutions
                                </h3>
                                <ul className="mt-4 space-y-4">
                                    <li>
                                        <Link to='/slack' className='text-base text-gray-500 hover:text-gray-900'>
                                            Slack App
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/membership' className='text-base text-gray-500 hover:text-gray-900'>
                                            Exclusive Discounts
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/membership' className='text-base text-gray-500 hover:text-gray-900'>
                                            On Demand App
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            {/* <div className="mt-12 md:mt-0">
                                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                                    Support
                                </h3>
                                <ul className="mt-4 space-y-4">
                                    <li>
                                        <Link to='/health' className='text-base text-gray-500 hover:text-gray-900'>
                                            Health & Safety
                                        </Link>
                                    </li>
                                </ul>
                            </div> */}
                        </div>
                        <div className="md:grid md:grid-cols-2 md:gap-8">
                            {/* <div>
                                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                                    Company
                                </h3>
                                <ul className="mt-4 space-y-4">
                                    <li>
                                        <a href="#" className="text-base text-gray-500 hover:text-gray-900">
                                            About
                                        </a>
                                    </li>

                                    <li>
                                        <a href="#" className="text-base text-gray-500 hover:text-gray-900">
                                            Blog
                                        </a>
                                    </li>

                                    <li>
                                        <a href="#" className="text-base text-gray-500 hover:text-gray-900">
                                            Press
                                        </a>
                                    </li>

                                    <li>
                                        <a href="#" className="text-base text-gray-500 hover:text-gray-900">
                                            Partners
                                        </a>
                                    </li>
                                </ul>
                            </div> */}
                            <div className="mt-12 md:mt-0">
                                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                                    Legal
                                </h3>
                                <ul className="mt-4 space-y-4">
                                    {/* <li>
                                        <Link to='/space-use' target='_blank' className='text-base text-gray-500 hover:text-gray-900'>
                                            Space Use License
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/host-terms' target='_blank' className='text-base text-gray-500 hover:text-gray-900'>
                                            Host Terms
                                        </Link>
                                    </li> */}

                                    <li>
                                        <Link to='privacy' target='_blank' className="text-base text-gray-500 hover:text-gray-900">
                                            Privacy
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to='terms' target='_blank' className="text-base text-gray-500 hover:text-gray-900">
                                            Terms
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-12 border-t border-gray-200 pt-8">
                    <p className="text-base text-gray-400 xl:text-center">
                        &copy; 2021 redezio, Inc. All rights reserved.
      </p>
                </div>
            </div>
        </footer>
    )
}