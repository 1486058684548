import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Fragment, useEffect, useState } from "react"
import { analytics, coworkers, handshake, office, personpicture, slack } from '../../assets'
import { signInAnonymously } from "../../utils/api"
import { Dialog, Transition } from '@headlessui/react'
import { submitEmailToWaitlist } from '../../utils/api'
import { Link } from 'react-router-dom'

import MessengerCustomerChat from 'react-messenger-customer-chat';

const stats = [
    { label: 'Desks', value: '375+' },
    { label: 'Businesses', value: '50+' },
    { label: 'Locations', value: '50+' },
    { label: 'Users', value: '1,000+' },
]
const logos = [
    { name: 'Transistor', url: 'https://tailwindui.com/img/logos/transistor-logo-gray-400.svg' },
    { name: 'Mirage', url: 'https://tailwindui.com/img/logos/mirage-logo-gray-400.svg' },
    { name: 'Tuple', url: 'https://tailwindui.com/img/logos/tuple-logo-gray-400.svg' },
    { name: 'Laravel', url: 'https://tailwindui.com/img/logos/laravel-logo-gray-400.svg' },
    { name: 'StaticKit', url: 'https://tailwindui.com/img/logos/statickit-logo-gray-400.svg' },
    { name: 'Workcation', url: 'https://tailwindui.com/img/logos/workcation-logo-gray-400.svg' },
]

const features = [
    {
        name: 'Desk booking',
        description: 'Allow you and your team to book desks when they want to go in.',
    },
    {
        name: 'Capacity management',
        description: 'Limit the number of people attending the office at any one time.',
    },
    {
        name: 'Contact tracing',
        description: 'If someone happens to report they are sick, quickly get a list of employees in contact.',
    },
    {
        name: 'Health surveys',
        description: 'Survey your employees before attending the office.',
    },
    {
        name: 'Team scheduling',
        description: 'Allow team members to setup recurring days and meet with who they need to.',
    },
    {
        name: 'Usage Analytics',
        description: 'See how much you are using your space and what your trends look like over time.',
    },
]

const footerNavigation = {
    main: [
        // { name: 'About', href: '#' },
        // { name: 'Blog', href: '#' },
        // { name: 'Jobs', href: '#' },
        // { name: 'Press', href: '#' },
        // { name: 'Accessibility', href: '#' },
        // { name: 'Partners', href: '#' },
    ],
    social: [
        // {
        //     name: 'Facebook',
        //     href: '#',
        //     icon: (props) => (
        //         <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        //             <path
        //                 fillRule="evenodd"
        //                 d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
        //                 clipRule="evenodd"
        //             />
        //         </svg>
        //     ),
        // },
        // {
        //     name: 'Instagram',
        //     href: '#',
        //     icon: (props) => (
        //         <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        //             <path
        //                 fillRule="evenodd"
        //                 d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
        //                 clipRule="evenodd"
        //             />
        //         </svg>
        //     ),
        // },
    ],
}

function SubmittedModal({ open, setOpen }) {
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={setOpen}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                            <div>
                                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                    <FontAwesomeIcon icon='check' className="text-blue-600" size='2x' />
                                </div>
                                <div className="mt-3 text-center sm:mt-5">
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                        Email Submitted Successfully
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Thank you for signing up for our beta. We will email you a link to signup when we have space.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-6">
                                <button
                                    type="button"
                                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                                    onClick={() => setOpen(false)}
                                >
                                    Thank You
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}




export default function SlackScreen() {

    const [email, setEmail] = useState()
    const [openModal, setOpenModal] = useState(false)

    useEffect(() => {
        (async () => {
            return signInAnonymously()
        })();
    }, [])

    function addEmailToWaitlist(e) {
        e.preventDefault()
        return submitEmailToWaitlist(email, 'slack').then(() => setOpenModal(true))
    }

    return (
        <div className="bg-white">
            <MessengerCustomerChat
                pageId="108612044771543"
                appId="955024938397778"
            />
            <main>
                <SubmittedModal open={openModal} setOpen={setOpenModal} />
                {/* Hero section */}
                <div className='flex items-center pt-8 pl-10 max-w-6xl mx-auto row justify-between'>
                    <Link to='/' className='flex row items-center justify-center'>
                        <FontAwesomeIcon icon='building' size='2x' className='text-blue-500 mr-3' />
                        <h1 className='text-blue-500'>Sacha</h1>
                    </Link>

                </div>
                <div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-48 grid grid-cols-1 row-end-2">
                    <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24 cols-span-1">
                        <div>
                            <div className="mt-20">
                                <div className="mt-6 sm:max-w-xl">
                                    <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                                        Easily reopen your office for free
                                    </h1>
                                    <p className="mt-6 text-xl text-gray-500">
                                        Have 100% of your team utilize the first free desk booking tool to coordinate who goes in and when with an existing tool, Slack. Sign up for our beta.
                                    </p>
                                </div>
                                <form onSubmit={e => addEmailToWaitlist(e)} className="mt-12 sm:max-w-lg sm:w-full sm:flex" id='first'>
                                    <div className="min-w-0 flex-1">
                                        <label htmlFor="hero_email" className="sr-only">
                                            Email address
                                        </label>
                                        <input
                                            id="hero_email"
                                            type="email"
                                            className="block w-full border border-gray-300 rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                            placeholder="Enter your email"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="mt-4 sm:mt-0 sm:ml-3">
                                        <button
                                            type="submit"
                                            className="block w-full rounded-md border border-transparent px-5 py-3 bg-blue-500 text-base font-medium text-white shadow hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:px-10"
                                        >
                                            Join our Beta
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="sm:mx-auto sm:max-w-3xl sm:px-6 row-start-1">
                        <div className="sm:py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                            <div className="hidden sm:block">
                                <div className="absolute inset-y-0 left-1/2 w-screen bg-gray-50 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full" />
                                <svg
                                    className="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0"
                                    width={404}
                                    height={392}
                                    fill="none"
                                    viewBox="0 0 404 392"
                                >
                                    <defs>
                                        <pattern
                                            id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                                            x={0}
                                            y={0}
                                            width={20}
                                            height={20}
                                            patternUnits="userSpaceOnUse"
                                        >
                                            <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                        </pattern>
                                    </defs>
                                    <rect width={404} height={392} fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
                                </svg>
                            </div>
                            <div className="relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
                                <img
                                    className="w-56 md:w-full lg:h-full lg:w-auto lg:max-w-none"
                                    src={slack}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-white">
                    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                        <p className="text-center text-base font-semibold uppercase text-gray-600 tracking-wider">
                            Trusted to reopen by businesses
                        </p>
                        <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
                            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                                <img
                                    className="max-h-12"
                                    src="https://tailwindui.com/img/logos/transistor-logo-gray-400.svg"
                                    alt="Workcation"
                                />
                            </div>
                            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                                <img className="max-h-12" src="https://tailwindui.com/img/logos/mirage-logo-gray-400.svg" alt="Mirage" />
                            </div>
                            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                                <img className="max-h-12" src="https://tailwindui.com/img/logos/tuple-logo-gray-400.svg" alt="Tuple" />
                            </div>
                            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                                <img className="max-h-12" src="https://tailwindui.com/img/logos/laravel-logo-gray-400.svg" alt="Laravel" />
                            </div>
                            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                                <img
                                    className="max-h-12"
                                    src="https://tailwindui.com/img/logos/statickit-logo-gray-400.svg"
                                    alt="StaticKit"
                                />
                            </div>
                            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                                <img
                                    className="max-h-12"
                                    src="https://tailwindui.com/img/logos/statamic-logo-gray-400.svg"
                                    alt="Statamic"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial/stats section */}
                <div className="relative mt-20 col-start-2">
                    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
                        <div className="relative sm:py-16 lg:py-0">
                            <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
                                <div className="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72" />
                                <svg
                                    className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
                                    width={404}
                                    height={392}
                                    fill="none"
                                    viewBox="0 0 404 392"
                                >
                                    <defs>
                                        <pattern
                                            id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                                            x={0}
                                            y={0}
                                            width={20}
                                            height={20}
                                            patternUnits="userSpaceOnUse"
                                        >
                                            <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                        </pattern>
                                    </defs>
                                    <rect width={404} height={392} fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
                                </svg>
                            </div>
                            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
                                {/* Testimonial card*/}
                                <div className="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
                                    <img
                                        className="absolute inset-0 object-cover h-full w-full "
                                        src={office}
                                        alt=""
                                    />
                                    <div className="absolute inset-0 bg-blue-500 mix-blend-multiply" />
                                    <div className="absolute inset-0 bg-gradient-to-t from-blue-600 via-blue-600 opacity-90" />
                                    <div className="relative px-8">
                                        <blockquote className="mt-8">
                                            <div className="relative text-lg font-medium text-white md:flex-grow">
                                                <svg
                                                    className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-blue-400"
                                                    fill="currentColor"
                                                    viewBox="0 0 32 32"
                                                    aria-hidden="true"
                                                >
                                                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                                                </svg>
                                                <p className="relative">
                                                    Sacha helped us create a safe hybrid return to work for our team. It’s so simple for our team members to get access to the office and it really assists us with keeping them safe.
                                                </p>
                                            </div>

                                            <footer className="mt-4">
                                                <p className="text-base font-semibold text-blue-200">Rosa, People and Culture</p>
                                            </footer>
                                        </blockquote>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
                            {/* Content area */}
                            <div className="pt-12 sm:pt-16 lg:pt-20">
                                <h2 className="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl">
                                    Reopen your office in 5-minutes
                                </h2>
                                <div className="mt-6 text-gray-500 space-y-6">
                                    <p className="text-lg">
                                        Sacha is so simple to use, you'll be up and running in 5-minutes.
                                    </p>
                                </div>
                            </div>

                            {/* Stats section */}
                            <div className="mt-10">
                                <dl className="grid grid-cols-2 gap-x-4 gap-y-8">
                                    {stats.map((stat) => (
                                        <div key={stat.label} className="border-t-2 border-gray-100 pt-6">
                                            <dt className="text-base font-medium text-gray-500">{stat.label}</dt>
                                            <dd className="text-3xl font-extrabold tracking-tight text-gray-900">{stat.value}</dd>
                                        </div>
                                    ))}
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>



                {/* Logo cloud section */}
                <div className="mt-32">
                    <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
                        <div className="lg:grid lg:grid-cols-2 lg:gap-24 lg:items-center">
                            <div>
                                <h2 className="text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                                    Track your Usage
                                </h2>
                                <p className="mt-6 max-w-3xl text-lg leading-7 text-gray-500">
                                    See how often people are going in and when it comes time to get your next lease, move to coworking space or sign up with us. Make the smarter decision.
                                </p>
                            </div>
                            <div className="mt-12 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-2">
                                <img
                                    className="w-56 md:w-full lg:h-full lg:w-auto lg:max-w-none"
                                    src={analytics}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-white">
                    <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
                        <div className="max-w-3xl mx-auto text-center">
                            <h2 className="text-3xl font-extrabold text-gray-900">All-in-one platform for Free</h2>
                            <p className="mt-4 text-lg text-gray-500">
                                Join the hybrid workforce with everything you need
                            </p>
                        </div>
                        <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-3 lg:gap-x-8">
                            {features.map((feature) => (
                                <div key={feature.name} className="relative">
                                    <dt>
                                        {/* <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" /> */}
                                        <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                                    </dt>
                                    <dd className="mt-2 ml-9 text-base text-gray-500">{feature.description}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>


                {/* CTA section */}
                <div className="relative mt-24 sm:mt-32 sm:py-16">
                    <div aria-hidden="true" className="hidden sm:block">
                        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50 rounded-r-3xl" />
                        <svg className="absolute top-8 left-1/2 -ml-3" width={404} height={392} fill="none" viewBox="0 0 404 392">
                            <defs>
                                <pattern
                                    id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
                                    x={0}
                                    y={0}
                                    width={20}
                                    height={20}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width={404} height={392} fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)" />
                        </svg>
                    </div>
                    <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                        <div className="relative rounded-2xl px-6 py-10 bg-blue-500 overflow-hidden shadow-xl sm:px-12 sm:py-20">
                            <div aria-hidden="true" className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
                                <svg
                                    className="absolute inset-0 h-full w-full"
                                    preserveAspectRatio="xMidYMid slice"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 1463 360"
                                >
                                    <path
                                        className="text-blue-400 text-opacity-40"
                                        fill="currentColor"
                                        d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
                                    />
                                    <path
                                        className="text-blue-600 text-opacity-40"
                                        fill="currentColor"
                                        d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
                                    />
                                </svg>
                            </div>
                            <div className="relative">
                                <div className="sm:text-center">
                                    <h2 className="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
                                        Join our beta and open your office as soon as possible
                                    </h2>
                                    <p className="mt-6 mx-auto max-w-2xl text-lg text-blue-100">
                                        Let's get back to work
                                    </p>
                                </div>
                                <form onSubmit={e => addEmailToWaitlist(e)} className="mt-12 sm:mx-auto sm:max-w-lg sm:flex" id='second'>
                                    <div className="min-w-0 flex-1">
                                        <label htmlFor="cta_email" className="sr-only">
                                            Email address
                                        </label>
                                        <input
                                            id="cta_email"
                                            type="email"
                                            className="block w-full border border-transparent rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-500"
                                            placeholder="Enter your email"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="mt-4 sm:mt-0 sm:ml-3">
                                        <button
                                            type="submit"
                                            className="block w-full rounded-md border border-transparent px-5 py-3 bg-gray-900 text-base font-medium text-white shadow hover:bg-black focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-500 sm:px-10"
                                        >
                                            Join Beta
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            {/* Footer section */}
            <footer className="mt-24 bg-gray-900 sm:mt-12">
                <div className="mx-auto max-w-md py-12 px-4 overflow-hidden sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                    <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
                        {footerNavigation.main.map((item) => (
                            <div key={item.name} className="px-5 py-2">
                                <a href={item.href} className="text-base text-gray-400 hover:text-gray-300">
                                    {item.name}
                                </a>
                            </div>
                        ))}
                    </nav>
                    <div className="mt-8 flex justify-center space-x-6">
                        {/* {footerNavigation.social.map((item) => (
                            <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-300">
                                <span className="sr-only">{item.name}</span>
                                <item.icon className="h-6 w-6" aria-hidden="true" />
                            </a>
                        ))} */}
                        {/* <a href="https://www.producthunt.com/posts/sacha?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-sacha" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=298901&theme=light" alt="Sacha - Airbnb for startup office space | Product Hunt" width="250" height="54" /></a> */}
                    </div>
                    <p className="mt-8 text-center text-base text-gray-400">&copy; 2021 redezio, Inc. All rights reserved.</p>
                </div>
            </footer>
        </div>
    )
}