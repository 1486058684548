import React from 'react'

export default function TextSplitter({string}){
    let stringBlocks = string.split('\n')
   return stringBlocks.map(block => {
       return (
       <>
            <p>{block}</p>
            <br/>
        </>
       ) 
    })
}