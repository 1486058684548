import firebase from './firebase'

export async function submitEmailToWaitlist(email, table){
    if (!firebase.auth().currentUser) {
        return;
    }
    return firebase.firestore().collection(table).add({
        email
    }).catch(e => console.error(e))

}

export async function submitEmailToSlackWaitlist(email) {
    if (!firebase.auth().currentUser) {
        return;
    }
    return firebase.firestore().collection('waitlistSlack').add({
        email
    }).catch(e => console.error(e))
}

export async function submitEmailToContact(email) {
    if (!firebase.auth().currentUser) {
        return;
    }
    return firebase.firestore().collection('contact').add({
        email
    }).catch(e => console.error(e))
}


export async function signInAnonymously() {
    if(!firebase.auth().currentUser){
        return firebase.auth().signInAnonymously().catch(e=> console.error(e))
    }
}


export async function resetPassword(email) {
    return firebase.auth().sendPasswordResetEmail(email)
}

export async function signOut() {
    return firebase.auth().signOut()
}

export function getCurrentUserId() {
    return firebase.auth().currentUser.uid
}

export async function createUserWithEmailAndPassword(email, password) {
    return firebase.auth().createUserWithEmailAndPassword(email, password).then(cred => {
        cred.user.sendEmailVerification();
    })
}

export async function signInWithEmailAndPassword(email, password) {
    return firebase.auth().signInWithEmailAndPassword(email, password)
}

export async function getUser() {
    if (!firebase.auth().currentUser) {
        return
    }
    const userId = firebase.auth().currentUser.uid

    return firebase.firestore().collection('users').doc(userId).get()
        .then(res => res.data())
        .catch(e => console.error(e))
}

export async function updateUser(data) {
    const userId = firebase.auth().currentUser.uid

    return firebase.firestore().collection('users').doc(userId).update(data).catch(e => console.error(e))

}

async function hydrateBuilding(building) {
    let promiseArr = []
    if (building.rooms) {
        building.rooms.forEach(r => {
            promiseArr.push(r.get().then(res => res.data()))
        })
    }
    if (building.desks) {
        building.desks.forEach(d => {
            promiseArr.push(d.get().then(res => res.data()))
        })
    }
    return Promise.all(promiseArr).then(data => {
        let rooms = []
        let desks = []
        data.forEach(d => {
            if (d.type === 'rooms') {
                rooms.push(d)
            } else {
                desks.push(d)
            }
        })
        return { ...building, rooms, desks }
    })
}


export async function getBuildingsForUser() {
    const userId = firebase.auth().currentUser.uid
    return firebase.firestore().collection('buildings')
        .where('ownerId', '==', userId)
        .get().then(res => {
            var tempSpacesArr = []
            res.forEach(s => {
                var data = s.data()
                data.id = s.id
                tempSpacesArr.push(data)
            })
            return tempSpacesArr
        }).then(arr => {
            let promiseArr = []
            arr.forEach(b => {
                promiseArr.push(hydrateBuilding(b))
            })
            return Promise.all(promiseArr)
        }).catch(e => console.error(e))
}

export async function getBuildingForId(spaceId) {
    if (!spaceId) {
        return
    }

    return firebase.firestore().collection('buildings')
        .doc(spaceId).get().then(res => {
            var data = res.data()
            data.id = res.id
            return data
        }).then(building => {
            return hydrateBuilding(building)
        })
        .catch(e => console.error(e))
}

export async function getReservationsForUser() {
    const userId = firebase.auth().currentUser.uid

    return firebase.firestore().collection('reservations')
        .where('building.ownerId', '==', userId)
        .get().then(res => {
            var tempResArr = []
            res.forEach(r => {
                var data = r.data()
                data.id = r.id
                tempResArr.push(data)
            })
            return (tempResArr)
        }).catch(e => console.error(e))
}

export async function getRegions() {
    return firebase.firestore().collection('regions').get().then(snap => {
        let regionArr = []
        snap.docs.map(r => {
            regionArr.push({ id: r.id, ...r.data() })
        })
        return regionArr
    }).catch(e => console.error(e))
}

export async function addBuilding(data) {
    // copy form so I can alter the data
    const building = Object.assign(data, {})
    // make other objecs so I can remove
    const formRooms = Object.assign(data.rooms, {})
    const deskTotal = data.deskTotal

    const batch = firebase.firestore().batch()

    const buildingRef = firebase.firestore().collection('buildings').doc()
    
    // TODO: change building ref to building id because the building ref reads are not stable

    // clean up the space form
    // delete building.deskTotal
    delete building.rooms
    building.rooms = []
    building.desks = []

    for (let i = 0; i < deskTotal; i++) {
        const ref = firebase.firestore().collection('desks').doc()
        building.desks.push(ref)
        batch.set(ref, {
            name: `${data.name}-desk-${i + 1}`,
            reservations: [],
            type: 'desks',
            building: buildingRef,
            region: building.region
        })
    }

    formRooms.forEach(r => {
        // r is just printing the index and not the values
        console.log('room', r)
        const ref = firebase.firestore().collection('rooms').doc()
        building.rooms.push(ref)
        batch.set(ref, {
            ...r,
            reservations: [],
            type: 'rooms',
            building:buildingRef,
            region: building.region
        })
    })

    return buildingRef.set(building).then(res => {
            batch.commit().catch(e => console.error(e))
            return res.id
        }).catch(e => console.error(e))
}

export async function editSpace(id, data) {
    // copy form so I can alter the data
    const building = Object.assign(data, {})
    // make other objecs so I can remove
    const formRooms = Object.assign(data.rooms, {})
    const deskTotal = data.deskTotal
    const deskArrLength = data.desks.length

    const batch = firebase.firestore().batch()

    //delete the current desks since it is data
    delete building.desks
    if (deskArrLength < deskTotal) {
        for (let i = deskArrLength - 1; i < deskTotal; i++) {
            const ref = firebase.firestore().collection('desks').doc()
            building.desks.push(firebase.firestore.FieldValue.arrayUnion(ref))
            batch.set(ref, {
                name: `${data.name}-desk-${i + 1}`,
                reservations: [],
                type: 'desks',
                building
            })
        }
    }
    delete building.rooms
    formRooms.forEach(r => {
        if (r.building) {
            return
        }
        console.log('room', r)
        const ref = firebase.firestore().collection('rooms').doc()
        building.rooms.push(firebase.firestore.FieldValue.arrayUnion(ref))
        batch.set(ref, {
            ...r,
            reservations: [],
            type: 'rooms',
            building
        })
    })

    return firebase.firestore().collection('buildings')
        .doc(id).update(data)
        .catch(e => console.error(e))
}

export async function uploadImage(photo, id) {
    let storageRef = firebase.storage().ref()
    var uploadTask = storageRef.child(`spaces/${id + Date.now()}`).put(photo);

    return uploadTask.on('state_changed',
        (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED:
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Upload is starting');
                    break;
            }
        },
        (error) => {
            console.error(error)
        },
        async () => {
            return uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                console.log('File available at', downloadURL);
                const union = firebase.firestore.FieldValue.arrayUnion(downloadURL)
                return firebase.firestore().collection('buildings').doc(id)
                    .update({
                        "images": union
                    })
                    .catch(e => console.error(e))
            });
        }
    );
}

export async function updateReservationStatus(id, status) {
    return firebase.firestore().collection('reservations')
        .doc(id).update({
            status
        }).catch(e => console.error(e))
}

export async function createStripeAccount() {
    const userId = firebase.auth().currentUser.uid
    const debug = process.env.NODE_ENV !== 'production'
    var createExpressAcct = firebase.functions().httpsCallable('createStripeAccount')
    return createExpressAcct({ userId, env: debug })
}

export async function createNewAccountLink(accountId) {
    const debug = process.env.NODE_ENV !== 'production'
    var createNewAccountLink = firebase.functions().httpsCallable('createNewAccountLink')
    return createNewAccountLink({ accountId, env: debug })
}

export async function getStripeTransfers(accountId) {
    var listTransfers = firebase.functions().httpsCallable('getStripeTransfers')
    return listTransfers({ accountId })
}

export async function getStripeAccount(accountId) {
    var getStripeAccount = firebase.functions().httpsCallable('getStripeAccount')
    return getStripeAccount({ accountId })
}

export async function submitEmailForWaitlist(email) {
    return firebase.firestore().collection('waitlist').add({ email }).catch(e => console.error(e))
}