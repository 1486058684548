import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
}

function initFirebase() {
    const app = firebase.initializeApp(config)
    firebase.analytics();
    
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        firebase.auth().useEmulator("http://localhost:9099");
        firebase.functions().useEmulator('localhost', 5001)
        firebase.firestore().useEmulator("localhost", 8080)
        // firebase.database().useEmulator('localhost', 9000)
        firebase.storage().useEmulator('localhost', 9199)
    }
    // firebase.firestore().enablePersistence()
    // .catch((err) => console.error(err));
    return app
}

const myFirebase = !firebase.apps.length ? initFirebase() : firebase

export default myFirebase