import { 
    createSlice,
    createSelector,
  } from '@reduxjs/toolkit'
  
  const paymentSlice = createSlice({
    name: 'payments',
    initialState: [],
    reducers: {
      addPaymentHistory: (state, action) => state = action.payload,
    }
  })
  
  export const { addPaymentHistory } = paymentSlice.actions
  
  export const getPaymentHistory = createSelector(state => state)
  
  export default paymentSlice.reducer