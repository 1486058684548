import {
  createSlice,
  createSelector,
} from '@reduxjs/toolkit'

const userSlice = createSlice({
  name: 'user',
  initialState: {},
  reducers: {
    addUser: (state, action) =>  state = action.payload
  }
})

export const { addUser } = userSlice.actions

export const getUser = createSelector(state => state)

export default userSlice.reducer