import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SimplePricing from '../../components/splash/SimplePricing'
import Footer from '../../components/splash/Footer'
import Feature from '../../components/splash/Feature'
import CompactFeatureSection from '../../components/splash/CompactFeatureSection'
import BottomCTASection from '../../components/splash/BottomCTASection'
import Lottie from 'react-lottie'
import { coworkingAnimation, customerService, freelancing, officeWork, workingBusinessTeam } from '../../assets'
import BigFeature from '../../components/splash/BigFeature'
// import { MenuIcon, XIcon } from '@heroicons/react/outline'
// import { ChevronRightIcon } from '@heroicons/react/solid'
// import { InboxIcon, SparklesIcon } from '@heroicons/react/outline'


const navigation = [
  { name: 'Your New Team', href: '#team' },
  // { name: 'Track Usage', href: '/slack' },
  { name: 'Exclusive Deals', href: '#deals' },
  // { name: 'On Demand Book', href: '/app' },
]

export default function HomeScreen() {
  return (
    <div className="relative overflow-hidden">
      <Popover as="header" className="relative">
        <div className="bg-gray-900 pt-6">
          <nav
            className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
            aria-label="Global"
          >
            <div className="flex items-center flex-1">
              <div className="flex items-center justify-between w-full md:w-auto">
                <a href="#">
                  <span className="sr-only">Sacha</span>
                  <FontAwesomeIcon icon='building' size='2x' className='text-blue-500 h-8 w-auto sm:h-10' />
                </a>
                <div className="-mr-2 flex items-center md:hidden">
                  <Popover.Button className="bg-gray-900 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {/* <MenuIcon className="h-6 w-6" aria-hidden="true" /> */}
                    <FontAwesomeIcon icon='bars' className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="hidden space-x-8 md:flex md:ml-10">
                {navigation.map((item) => (
                  <a key={item.name} href={item.href} className="text-base font-medium text-white hover:text-gray-300">
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
            {/* <div className="hidden md:flex md:items-center md:space-x-6">
              <a href="#" className="text-base font-medium text-white hover:text-gray-300">
                Log in
              </a>
              <a
                href="#"
                className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700"
              >
                Start free trial
              </a>
            </div> */}
          </nav>
        </div>

        <Transition
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel focus className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top md:hidden">
            <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="px-5 pt-4 flex items-center justify-between">
                <div>
                  {/* <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/workflow-mark-blue-600.svg"
                    alt=""
                  /> */}
                  <FontAwesomeIcon icon='building' size='2x' className='text-blue-500 h-8 w-auto' />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-600">
                    <span className="sr-only">Close menu</span>
                    {/* <XIcon className="h-6 w-6" aria-hidden="true" /> */}
                    <FontAwesomeIcon icon='times' />
                  </Popover.Button>
                </div>
              </div>
              <div className="pt-5 pb-6">
                <div className="px-2 space-y-1">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                {/* <div className="mt-6 px-5">
                  <a
                    href="#"
                    className="block text-center w-full py-3 px-4 rounded-md shadow bg-blue-600 text-white font-medium hover:bg-blue-700"
                  >
                    Start free trial
                  </a>
                </div>
                <div className="mt-6 px-5">
                  <p className="text-center text-base font-medium text-gray-500">
                    Existing customer?{' '}
                    <a href="#" className="text-gray-900 hover:underline">
                      Login
                    </a>
                  </p>
                </div> */}
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>

      <main>
        <div className="pt-10 bg-gray-900 sm:pt-16 lg:pt-8 lg:pb-14 overflow-hidden">
          <div className="mx-auto max-w-7xl lg:px-8">
            <div className="lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                <div className="lg:py-24">
                  <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                    <span className="">The Real Estate Team for</span>
                    <span className=" text-blue-400"> your Business</span>
                  </h1>
                  <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                    Become as dynamic as your employees regardless of your office model.
                  </p>
                  <div className="mt-10 sm:mt-12">
                    <a href='https://calendly.com/ryansacha/office-space-discovery-call'
                      target='_none'
                      className="text-center block w-full py-3 px-4 rounded-md shadow bg-blue-500 text-white font-medium hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300 focus:ring-offset-gray-900"
                    >
                      Set up a discovery call
                    </a>
                    {/* <form action="#" className="sm:max-w-xl sm:mx-auto lg:mx-0">
                      <div className="sm:flex">
                        <div className="min-w-0 flex-1">
                          <label htmlFor="email" className="sr-only">
                            Email address
                          </label>
                          <input
                            id="email"
                            type="email"
                            placeholder="Enter your email"
                            className="block w-full px-4 py-3 rounded-md border-0 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300 focus:ring-offset-gray-900"
                          />
                        </div>
                        <div className="mt-3 sm:mt-0 sm:ml-3">
                          <button
                            type="submit"
                            className="block w-full py-3 px-4 rounded-md shadow bg-blue-500 text-white font-medium hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300 focus:ring-offset-gray-900"
                          >
                            Set up a discovery call
                          </button>
                        </div>
                      </div>
                    </form> */}
                  </div>
                </div>
              </div>
              <div className="mt-12 mb-16 sm:-mb-48 lg:m-0 lg:relative">
                <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 ">
                  {/* Illustration taken from Lucid Illustrations: https://lucid.pixsellz.io/ */}
                  {/* <img
                    className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                    src="https://tailwindui.com/img/component-images/cloud-illustration-blue-400.svg"
                    alt=""
                  /> */}
                  <div className='w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none'>
                    <Lottie
                      options={
                        {
                          loop: true,
                          autoplay: true,
                          animationData: workingBusinessTeam
                        }
                      } />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* More main page content here... */}
        <CompactFeatureSection />
        <Feature
          title='Dedicated Team'
          description='We will identify what you need together. Our team will set it up for you and track its usage, updating dynamically so you will not waste a penny.'
          lottie={officeWork}
        />
        <BigFeature
          id='team'
          topTitle='Your New Team'
          title='Your new AI Enabled Real Estate Team'
          description='We built a custom artificial intelligence platform that monitors the usage of your office space to recommend new options to cut costs and increase quality.'   
          lottie={customerService}     
        />
        <Feature
          direction='right'
          title='Easy Expense Tracking'
          description='We will setup what you need an put it under one line item in your account'
          lottie={freelancing}
        />
        <BigFeature
          id='deals'
          topTitle='Exclusive Discounts'
          title='Save at least 700 per employee'
          description='We offer exclusive deals and discounts for your team on both dedicated and dynamic space.'
          lottie={coworkingAnimation}
          />
        <BottomCTASection />
        {/* <SimplePricing price='5%' /> */}
      </main>
      <Footer />
    </div>
  )
}



