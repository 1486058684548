// import { AnnotationIcon, GlobeAltIcon, LightningBoltIcon, ScaleIcon } from '@heroicons/react/outline'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const features = [
  {
    name: 'Identify your Needs',
    description:
      'In our discovery call, we will identify what your team looks like, where your employees are located and the best pathway forward.',
    icon: 'search',
  },
  {
    name: 'Setup What you Need',
    description:
      'We will setup everything you need rather that be a hub and spoke model, fully decentralized or completely centralized',
    icon: 'tools',
  },
  {
    name: 'Analyze your Usage',
    description:
      'We will monitor the usage of your portfolio daily, rather an office is over-used or under-utilized, we will track it and keep you in the loop',
    icon: 'chart-line',
  },
  {
    name: 'Dyncamically Adjust your Portfolio',
    description:
      'If something is under or over utilized, we will dynamically forecast and suggest changes to your portfolio using artificial intelligence so you can be as dynamic as possible, saving you money in the process.',
    icon: 'atom',
  },
]

export default function CompactFeatureSection() {
  return (
    <div className="py-12 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">The New Real Estate Team</h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Be as Dynamic as your Employees
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            Regardless of your real estate model in the wave of the new office, we have got you covered. 
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                    <FontAwesomeIcon icon={feature.icon} className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}