import React from 'react'
import { useLocation } from 'react-router'
import { building } from '../../assets'
import Footer from '../../components/splash/Footer'
import Header from '../../components/splash/Header'
import Navigation from '../../components/splash/Navigation'
import * as _ from 'lodash'

export default function SubmissionScreen() {
    const location = useLocation()
    const appInstall =  _.get(location, 'state.appInstall', false)

    var title
    var description

    if (appInstall) {
        title = 'Thank you for joining our Waitlist'
        description = 'We will notifiy you once it is available'
    } else {
        title = 'Thank you for reaching out!'
        description = 'We will be in contact shortly. We look forward to speaking with you'
    }

    return (

        <div className='bg-white'>
            <Navigation />
            <Header title={title} description={description} />
            <img className=' sm:w-full max-w-md mx-auto mt-5 flex flex-center' src={building} alt='Have a nice day' />
            <Footer />
        </div>
    )
}